const blogCards = [
  {
    description: 'En esta categoria vas a ver cosas de Salud mental',
    image: '../../../casco1.png',
    label: 'Salud',
    path: 'salud-mental',
  },
  {
    description: 'En esta categoria vas a ver cosas de Negocios',
    image: '../../../casco1.png',
    label: 'Negocios',
    path: 'profesion-negocios',
  },
  {
    description: 'En esta categoria vas a ver cosas de Desarrollo Personal',
    image: '../../../casco1.png',
    label: 'Desarrollo Personal',
    path: 'desarrollo-personal',
  },
  {
    description: 'En esta categoria vas a ver cosas de Abundancia',
    image: '../../../casco1.png',
    label: 'Abundancia',
    path: 'abundancia',
  },
];

const comunidadCards = [
  {
    description: 'Aca un poco de nosotros',
    image: '../../../casco1.png',
    label: 'Streams',
    path: 'streams',
  },
  {
    description: 'Queres stremear, contactanos',
    image: '../../../casco1.png',
    label: '¿Queres Stremear?',
    path: 'quiero-stremear',
  },
];

const nosotrosCards = [
  {
    description: 'En esta categoria vas a conocer sobre nuestra Historia',
    image: '../../../casco1.png',
    label: 'Historia',
    path: 'sobre-srv',
  },
  {
    description: 'En esta categoria vas a conocer sobre nuestra Mision',
    image: '../../../casco1.png',
    label: 'Mision',
    path: 'mision-vision',
  },
  {
    description: 'En esta categoria vas a poder Participar',
    image: '../../../casco1.png',
    label: 'Participa',
    path: 'participa',
  },
  {
    description:
      'En esta categoria vas a poder convertirte en un Historia de Exito',
    image: '../../../casco1.png',
    label: 'Historias de exito',
    path: 'historias-de-exito',
  },
  {
    description:
      'En esta categoria vas a poder conocer a los fundadores de SRV',
    image: '../../../casco1.png',
    label: 'Founders',
    path: 'profile',
  },
];

const tiendaCards = [
  {
    description: 'Aqui encontraras todos nuestros productos',
    image: '../../../casco1.png',
    label: 'Todos Los Productos',
    path: 'todos-los-productos',
  },
  {
    description: 'Necesitas ayuda con tu negocio, contactanos',
    image: '../../../casco1.png',
    label: 'Coaching de Negocios',
    path: 'negocios',
  },
  {
    description: 'El camino a una vida mejor',
    image: '../../../casco1.png',
    label: 'Coaching de Vida',
    path: 'vida',
  },
  {
    description: 'Aqui encontraras todos nuestros talleres',
    image: '../../../casco1.png',
    label: 'Talleres',
    path: 'talleres',
  },
];

const profileCards = [
  {
    data: {
      email: '',
      github: {
        exits: false,
      },
      instagram: 'https://www.instagram.com/fab333_/',
      location: 'Buenos Aires, Argentina',
    },
    description: 'CEO y Coach Empresarial de Alto Rendimiento',
    function: 'Falta redactar por Fabio',
    history: 'Falta redasctar por Fabio',
    hobbys: 'Falta redactar por Fabio',
    image: '../../../profiles/fabio.png',
    label: 'Fabio Gomez Ramirez',
    path: 'fabio-gomez',
  },
  {
    data: {
      email: 'mailto:milagros.espinosa@wearesrv.com',
      github: false,
      instagram: 'https://www.instagram.com/mily_espinosa/',
      location: 'Buenos Aires, Argentina',
    },
    description: 'Head of Design y Community',
    function: 'Falta redactar por Mili',
    history: 'Falta redasctar por Mili',
    hobbys: 'Falta redactar por Mili',
    image: '../../../profiles/milei.png',
    label: 'Milagros Espinosa',
    path: 'milagros-espinosa',
  },
  {
    data: {
      email: 'mailto:agustin.fernandez@wearesrv.com',
      github: 'https://github.com/PablinkiDev',
      instagram: 'https://www.instagram.com/aguuspaablo/',
      location: 'Buenos Aires, Argentina',
    },
    description: 'Head of Learning y Educador',
    function:
      'Desde la mitad de 2023, he asumido un rol clave en la operativa educativa de la empresa, encargándome de la creación y gestión de cursos exitosos, como el curso de Scripting con JavaScript y Python. Mi responsabilidad incluye la planificación, preparación del material y ejecución de estos cursos, siendo el principal responsable de toda la parte educativa en SRV. Además, me desempeño como Desarrollador Frontend, contribuyendo a los proyectos de la empresa en esta área. En resumen, ejerzo tanto como Instructor como Desarrollador, combinando mis habilidades en la formación y el desarrollo de software.',
    history:
      'Desde una edad temprana, a los dos años, desarrollé un interés por la informática y los videojuegos, lo que marcó el inicio de una pasión que se ha mantenido a lo largo de los años. En 2021, decidí formalizar mi interés y comenzar mi carrera en el desarrollo de software. En el último año y medio, me he especializado en el desarrollo FrontEnd, profundizando en las tecnologías y técnicas necesarias para crear interfaces de usuario efectivas y atractivas.',
    hobbys:
      'Me apasiona jugar videojuegos y, cuando busco nuevas actividades, suelo dedicarme a estudiar temas relacionados con la programación. También tengo un interés en el ámbito de la inversión. Sin embargo, lo que más disfruto es colaborar con mi colega Marcos en el desarrollo de páginas web, una actividad que encuentro particularmente gratificante.',
    image: '../../../profiles/agus.png',
    label: 'Agustin Pablo Fernandez',
    path: 'agustin-fernandez',
  },
  {
    data: {
      email: 'mailto:marcos.costa@wearesrv.com',
      github: 'https://github.com/marcosSRV',
      instagram: 'https://www.instagram.com/marcosschmite/',
      location: 'Buenos Aires, Argentina',
    },
    description: 'CTO y COO',
    function: '',
    history: '',
    hobbys: '',
    image: '../../../profiles/marcos.png',
    label: 'Marcos Roberto Costa Schmite',
    path: 'marcos-schmite',
  },
];

const profilesData = [
  {
    content: {
      function:
        'Desde la mitad de 2023, he asumido un rol clave en la operativa educativa de la empresa, encargándome de la creación y gestión de cursos exitosos, como el curso de Scripting con JavaScript y Python. Mi responsabilidad incluye la planificación, preparación del material y ejecución de estos cursos, siendo el principal responsable de toda la parte educativa en SRV. Además, me desempeño como Desarrollador Frontend, contribuyendo a los proyectos de la empresa en esta área. En resumen, ejerzo tanto como Instructor como Desarrollador, combinando mis habilidades en la formación y el desarrollo de software.',
      history:
        'Desde una edad temprana, a los dos años, desarrollé un interés por la informática y los videojuegos, lo que marcó el inicio de una pasión que se ha mantenido a lo largo de los años. En 2021, decidí formalizar mi interés y comenzar mi carrera en el desarrollo de software. En el último año y medio, me he especializado en el desarrollo FrontEnd, profundizando en las tecnologías y técnicas necesarias para crear interfaces de usuario efectivas y atractivas.',
      hobbys:
        'Me apasiona jugar videojuegos y, cuando busco nuevas actividades, suelo dedicarme a estudiar temas relacionados con la programación. También tengo un interés en el ámbito de la inversión. Sin embargo, lo que más disfruto es colaborar con mi colega Marcos en el desarrollo de páginas web, una actividad que encuentro particularmente gratificante.',
      role: 'Head of Learning y Educador',
      skills: ['Frontend Developer', 'Python Developer', 'Educador'],
    },
    data: {
      avatar: '../../../profiles/agus.png',
      location: 'CABA, Argentina',
      name: 'Agustín Pablo Fernandez',
      path: 'agustin-fernandez',
      short_name: 'Agus',
    },
    networks: [
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/aguuspaablo/',
      },
      {
        name: 'GitHub',
        url: 'https://github.com/PablinkiDev',
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/agust%C3%ADn-pablo-fernandez-44b758315/',
      },
      {
        name: 'Email',
        url: 'agustin.fernandez@wearesrv.com',
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/QuAmDxrNMu',
      },
    ],
  },
  {
    content: {
      function:
        'Comencé con SRV en agosto del 2023 colaborando con el material visual relacionado a las redes. Desde entonces mis responsabilidades fueron mutando, hoy en día estoy a cargo del área creativa que abarca todo material visual analógico y digital.',
      history:
        'Desde que tengo memoria me apasiona lo artistico. Comencé de chica con el dibujo como hobbie y aprendí las bases en Polivalente, una escuela secundaria de artes visuales. Actualmente estudio Diseño Industrial de Indumentaria en la UNMDP, donde no solo aprendí sobre indumentaria, también adquirí una gran cantidad de conocimiento sobre la esencia del diseño en sus distintos campos.',
      hobbys:
        'Dibujar, hacer yoga, salir con amigos, tener largas charlas sobre la vida',
      role: 'Head of Design y Community',
      skills: ['Designer', 'Figma Engineer'],
    },
    data: {
      avatar: '../../../profiles/milei.png',
      location: 'Mar del Plata, Argentina',
      name: 'Milagros Espinosa',
      path: 'milagros-espinosa',
      short_name: 'Mily',
    },
    networks: [
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/mily_espinosa/',
      },
      {
        name: 'Email',
        url: 'milagros.espinosa@wearesrv.com',
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/QuAmDxrNMu',
      },
    ],
  },
  {
    content: {
      function:
        'Hoy me dedico a la educación, brindando clases de apoyo en programación, y también me ocupo de la parte de innovación en la empresa. Disfruto enormemente enseñar y, al mismo tiempo, me apasiona seguir programando y mejorando mis habilidades como desarrollador.',
      history:
        'Desde pequeño, sabía que me dedicaría a la tecnología. Hoy en día, tengo un propósito claro y firme: ayudar y estar al servicio de los demás. Creo profundamente que la mejor forma de lograrlo es mediante la tecnología.',
      role: 'CTO y COO',
      hobbys:
        'En mis tiempos libres, me gusta programar y jugar videojuegos. Uno de mis juegos favoritos es Minecraft, y también disfruto un poco de League of Legends (LoL).',
      skills: ['FullStack Developer', 'DevSecOps', 'Tecnico en Computacion'],
    },
    data: {
      avatar: '../../../profiles/marcos.png',
      location: 'Buenos Aires, Argentina',
      name: 'Marcos Costa',
      path: 'marcos-schmite',
      short_name: 'Marcos',
    },
    networks: [
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/marcosschmite/',
      },
      {
        name: 'GitHub',
        url: 'https://github.com/marcosSRV',
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/marcos-roberto-costa-schmite-9632a8182/',
      },
      {
        name: 'Email',
        url: 'marcos.costa@wearesrv.com',
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/QuAmDxrNMu',
      },
    ],
  },
  {
    content: {
      function: '',
      history: '',
      hobbys: '',
      role: 'CEO y Coach Empresarial de Alto Rendimiento',
      skills: ['Designer', 'Figma Engineer'],
    },
    data: {
      avatar: '../../../profiles/fabio.png',
      location: 'Buenos Aires, Argentina',
      name: 'Fabio Gomez',
      path: 'fabio-gomez',
      short_name: 'Fa',
    },
    networks: [
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/fab333_/',
      },
      {
        name: 'Email',
        url: 'fabio.gomez@wearesrv.com',
      },
      {
        name: 'Discord',
        url: 'https://discord.gg/QuAmDxrNMu',
      },
    ],
  },
];

module.exports = {
  blogCards,
  comunidadCards,
  nosotrosCards,
  tiendaCards,
  profileCards,
  profilesData,
};
