import React from 'react';
import './Propuesta.css';
import GifSwitcher from './GifSwitcher';

function PropuestaFormacion() {
  const pdf =
    'https://drive.usercontent.google.com/u/0/uc?id=1HqIUAs-0KtFMlNeDmhajw5-Ye2bEdVzI&export=download';

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdf;
    link.download = 'propuesta.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <html lang="es">
        <head>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>
            Propuesta de Formación en Programación Integral (PI) para Scouts de
            Argentina
          </title>
        </head>
        <body
          id="propuesta-formacion"
          style={{
            fontFamily:
              "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
            background:
              'linear-gradient(-45deg, , #00FFD1, #7303C0, #21083D, #010613, #7303C0, #FF7318)',
            color: 'white',
            margin: 0,
            padding: 0,
            height: '100%',
            width: '100%',
          }}
        >
          <table
            align="center"
            width="100%"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{ maxWidth: '1080px' }}
          >
            <tr>
              <td
                style={{
                  padding: '1em',
                  background: 'rgba(0, 0, 0, 0.4)',
                  boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                  textAlign: 'center',
                }}
              >
                <a
                  href="https://www.wearesrv.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://wearesrv.com/static/media/SRV-white.8810023aed882dd73ddd.png"
                    alt="Logo SRV"
                    style={{ height: '50px', marginBottom: '20px' }}
                  />
                </a>
                <a
                  href="https://scouts.org.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://scouts.org.ar/wp-content/uploads/2021/08/Logo-e1628194827916.png"
                    alt="Logo SAAC"
                    style={{ height: '80px', marginBottom: '20px' }}
                  />
                </a>
                <h1 style={{ fontSize: '24px', margin: '0 0 10px 0' }}>
                  Propuesta de Formación en Programación Integral para Scouts de
                  Argentina
                </h1>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Estimados miembros del Consejo Directivo de Scouts de
                  Argentina,
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Nos dirigimos a ustedes con una propuesta innovadora y
                  transformadora, en la cual les ofrecemos a todos los afiliados
                  de Scouts de Argentina Asociación Civil (SAAC) acceso gratuito
                  a nuestro módulo de JavaScript, desde nivel Cero hasta
                  Avanzado. En SRV, creemos en el poder de la educación
                  tecnológica donde preparamos a los jóvenes para los desafíos
                  presentes y futuros y estamos comprometidos con el desarrollo
                  integral de las personas.
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Nuestro objetivo principal es brindar a los jóvenes scouts la
                  oportunidad de desarrollar sus habilidades técnicas en
                  programación, junto con habilidades blandas esenciales. En SRV
                  buscamos preparalos para un mundo cada vez mas digital y
                  competitivo donde lamentablemente de forma gradual se fue
                  perdiendo el foco de los principios y valores que nos guian a
                  desarrollarnos en comunidad con un objetivo en comun, dejar el
                  mundo en mejores condiciones del que lo encontramos. Fomentar
                  un sentido de pertenencia y objetivos a largo plazo entre los
                  participantes, es una de nuestras metas, contribuyendo al
                  crecimiento personal y profesional de cada uno de ellos
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Todos los afiliados a SAAC podrán acceder de manera gratuita a
                  nuestro módulo "JavaScript: Desde Cero". Este curso está
                  diseñado para llevar a los participantes desde los conceptos
                  básicos de programación hasta el desarrollo de aplicaciones
                  avanzadas. Entre los temas que cubrimos se incluyen:
                </p>
                <ul style={{ textAlign: 'left' }}>
                  <li>
                    Introducción a la Programación y JavaScript: Programación,
                    lenguajes y JavaScript. NodeJS, Git, GitHub, y Visual Studio
                    Code
                  </li>
                  <li>
                    Variables y tipos de datos: Variables, Tipos de Datos,
                    Operadores Aritméticos, Comparación y Incremento y
                    Decremento
                  </li>
                  <li>
                    Controladores de Flujo: Operadores Lógicos y Tabla de
                    verdad. Diagramas de flujo. Estructura If, y Switch
                  </li>
                  <li>
                    Funciones y Tipos de Funciones: ¿Qué es una función? y sus
                    tipos.
                  </li>
                  <li>
                    Objeto Date y Math: ¿Cómo funcionan? Ejemplos de uso.
                    Funciones flechas
                  </li>
                  <li>
                    Estructura Repetitiva: While, do while, for, for in, for of
                  </li>
                  <li>
                    Estructura de Datos | Arrays: Arrays, Matrices. Métodos:
                    push, pop, shift, unshift, concat, slice, filter.
                  </li>
                  <li>
                    Estructura de Datos | Arrays II: Arrays II, Métodos:
                    includes, every, split, join, sort, indexOf, forEach y Map
                  </li>
                  <li>
                    Estructura de Datos | Objetos: Objetos, Callbacks e
                    Introducción a JSON
                  </li>
                  <li>Introducción a las Clases: Clases y uso de JSON</li>
                  <li>
                    Promesas: Introducción, clase Promise, y simulación de
                    procesos asincrónicos
                  </li>
                  <li>Promesas II: Async Await, Axios, ejemplos reales</li>
                </ul>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Además del módulo de JavaScript, ofrecemos una serie de
                  talleres y webinars que abarcan temas cruciales para el
                  desarrollo integral de las personas, incluyendo:
                </p>
                <ul style={{ textAlign: 'left' }}>
                  <li>Educación Financiera</li>
                  <li>Educación Emocional</li>
                  <li>Desarrollo Personal y Profesional</li>
                  <li>Desarrollo de Alto Rendimiento</li>
                  <li>Desarrollo de la Espiritualidad</li>
                  <li>Introducción al Marketing Digital</li>
                  <li>Gestión de Proyectos</li>
                  <li>Trabajo en Equipo y Liderazgo</li>
                  <li>Mindfulness y Gestión del Estrés</li>
                </ul>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Estas sesiones están diseñadas para proporcionar a los jóvenes
                  conocimientos y herramientas prácticas que les ayudarán en su
                  vida diaria, en su futuro personal y profesional. Todos
                  nuestros talleres y webinars pueden ser coordinados para
                  establecer los días y horarios más convenientes para ambas
                  partes aumentando así el impacto y beneficio para aquellos que
                  decidan participar.
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Nuestros cursos y charlas están diseñados y brindados por
                  personas competentes, altamente comprometidas y responsables.
                  Utilizamos metodologías de enseñanza que fomentan la
                  participación activa, el aprendizaje práctico y el desarrollo
                  de habilidades críticas para el éxito en el mundo moderno.
                  Cabe aclarar que contamos tanto con modalidad presencial como
                  virtual.
                </p>
                <blockquote
                  style={{
                    margin: '20px',
                    padding: '10px',
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderLeft: '5px solid #FF7318',
                  }}
                >
                  "Los últimos 6 años intenté aprender a programar, pasé por
                  muchos profesores y escuelas, pero mayormente mi dislexia no
                  me permitía avanzar mucho. Por suerte, encontré SRV por un
                  amigo. Aquí me enseñaron realmente a programar y a desarrollar
                  la lógica. Hoy en día ya aprendí a programar en Python y en
                  JavaScript."
                  <br />– Lautaro Reynoso, Alumno de SRV.
                </blockquote>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  En SRV brindamos soporte continuo a nuestros estudiantes,
                  asegurando que todos y cada uno de ellos reciba la atención y
                  el apoyo necesarios para sobresalir. Además, ofrecemos
                  oportunidades de práctica y proyectos reales donde van a poner
                  a prueba sus nuevas habilidades.
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Este programa está diseñado para crecer y adaptarse a las
                  necesidades de los miembros de SAAC. Nuestra visión es
                  expandir esta oferta para que todos los Caminantes y Rovers
                  interesados en programación puedan beneficiarse de esta
                  formación y, el día de mañana, transmitir información
                  tecnológica valiosa y efectiva basada en principios y valores
                  a ramas menores y adultos, incluyendo familias y formadores
                  interesados.
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Estamos mas que dispuestos a discutir esta propuesta en
                  detalle y explorar cómo desarrollar una sinergia sostenible y
                  ecológica. Tenemos Fe en que, juntos, podemos inspirar y
                  preparar no solamente a aquellos que estén decididos y listos,
                  sino también a aquellos a quienes la incertidumbre no les
                  permite forjar su camino, brindándoles herramientas para poder
                  sortear los escollos que van surgiendo en la Vida.
                </p>
                <button
                  className="btn-2"
                  onClick={handleDownload}
                  style={{
                    margin: '20px',
                    padding: '10px',
                    backgroundColor: '#00FFD1',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Descargar Propuesta
                </button>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Quedamos expectantes a su respuesta, cualquier duda estamos a
                  disposición SL⚜️.
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  Cordialmente,
                </p>
                <p style={{ margin: '20px 0', textAlign: 'left' }}>
                  <a
                    href="mailto:marcos.costa@wearesrv.com"
                    style={{ color: '#00FFD1' }}
                  >
                    Marcos Costa - Founder
                  </a>
                  <br />
                  <a
                    href="mailto:fabio.gomez@wearesrv.com"
                    style={{ color: '#00FFD1' }}
                  >
                    Fabio Gomez - Co Founder
                  </a>
                  <br />
                  Equipo de SRV
                </p>
                <p style={{ margin: '10px 0' }}>
                  Derechos reservados © SRV Education 2024
                </p>
                <p style={{ margin: '10px 0' }}>
                  Email:
                  <b>info@wearesrv.com</b> WhatsApp:
                  <b>+541166300591</b>
                </p>
                <p style={{ margin: '10px 0' }}>
                  Más Información en:
                  <b>www.wearesrv.com</b>
                </p>
              </td>
            </tr>
          </table>
          <GifSwitcher />
        </body>
      </html>
    </div>
  );
}

export default PropuestaFormacion;
