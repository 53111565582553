import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import wearesrv from './images/WEARESRV.COM.png';
import quienesomos from './images/quienesSomos.png';
import Video from './Video';
import CustomForm from './CustomForm';

function LandingInfoProducto() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNoClick = () => {
    navigate('/landing/conocenos');
    window.scrollTo({ top: 0 });
  };
  const handleSiClick = () => {
    navigate('/javascript-desde-cero');
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <Helmet>
        <title>Genera Ingresos Pasivos con SRV</title>
        <meta
          name="description"
          content="Descubre cómo generar ingresos pasivos recurrentes con nuestras habilidades y comienza a facturar en dólares en poco tiempo."
        />
        <meta
          name="keywords"
          content="ingresos pasivos, SRV, habilidades, facturar en dólares, educación tecnológica"
        />
      </Helmet>
      <div
        style={{
          padding: '20px',
          textAlign: 'center',
          backgroundColor: 'black',
        }}
      >
        <motion.div
          initial={{ textShadow: '0 0 10px #fff' }}
          animate={{
            textShadow: [
              '0 0 10px #fff',
              '0 0 20px #f0f',
              '0 0 30px #f0f',
              '0 0 20px #f0f',
              '0 0 10px #fff',
            ],
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          <Typography
            fontSize={isMobile ? '20px' : '60px'}
            gutterBottom
            style={{
              margin: '20px 0',
              color: '#fff',
              fontWeight: 'bold',
            }}
          >
            PODES GENERAR INGRESOS PASIVOS RECURRENTES CON ESTAS HABILIDADES EN
            MUY POCO TIEMPO, ¿QUERES SABER CÓMO?
          </Typography>
        </motion.div>
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          gap={4}
        >
          <motion.div
            animate={{ x: [0, 10, -10, 10, -10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
            whileHover={{ filter: 'brightness(1.5)' }}
          >
            <motion.button
              style={{
                fontSize: 'calc(1.2rem + 0.5vw)',
                padding: '25px 40px',
                backgroundColor: '#4caf50',
                border: 'none',
                borderRadius: '5px',
                color: 'white',
                cursor: 'pointer',
                outline: 'none',
                margin: '10px',
                minWidth: '60px',
              }}
              animate={{
                opacity: [1, 0.7, 1],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: 'reverse',
              }}
              onClick={handleSiClick}
            >
              SI
            </motion.button>
          </motion.div>
          <Button
            variant="contained"
            color="error"
            onClick={handleNoClick}
            style={{
              fontSize: 'calc(1rem + 0.5vw)',
              padding: '18px 36px',
              margin: '10px',
              minWidth: '50px',
            }}
          >
            NO
          </Button>
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <img
            src={wearesrv}
            alt="We Are SRV"
            style={{ maxWidth: '66%', height: 'auto' }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h4"
          gutterBottom
          style={{
            margin: '40px 0 20px',
            color: '#fafafa',
          }}
        >
          ¿QUIÉNES SOMOS?
        </Typography>
        <Box mt={4} display="flex" justifyContent="center">
          <img
            src={quienesomos}
            alt="Quienes Somos"
            style={{ maxWidth: '66%', height: 'auto' }}
          />
        </Box>
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          style={{
            margin: '40px 0 20px',
            color: '#fafafa',
            maxWidth: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'justify',
          }}
        >
          SRV: Revolución Educativa y Tecnológica.
          <p />
          Bienvenidos a SRV 👋 donde cada día es una oportunidad para reinventar
          la forma en que aprendemos y enseñamos. Somos pioneros en la Neo
          Educación, un concepto que va más allá de las aulas tradicionales,
          impulsando la libertad, empatía, innovación, diversidad y creatividad.
          <p />
          Imaginá un mundo donde la educación no es solo un proceso, sino una
          experiencia transformadora, adaptada a cada individuo, tal como lo
          hicieron gigantes como Microsoft y Amazon en sus campos. En SRV, no
          soñamos con el futuro; lo construimos.
          <p />
          Con tecnología de vanguardia, estamos redefiniendo el aprendizaje para
          que sea tan revolucionario como los cohetes que se lanzan al espacio.
          <p />
          Nuestra misión es clara: liberar el potencial humano a través de una
          educación que valora y celebra cada voz, cada idea, cada sueño.
          Creamos un espacio donde la diversidad es la norma, no la excepción, y
          donde la empatía es el idioma que nos une.
          <p />
          Al igual que grandes visionarios han cambiado la forma en que
          interactuamos con la tecnología, nosotros estamos transformando cómo
          nos conectamos y aprendemos juntos. La Neo Educación de SRV es más que
          un método; es un movimiento. Un movimiento hacia un aprendizaje
          profundo, auténtico y personalizado.
          <p />
          Pero, ¿por qué ahora? Porque el mundo no puede esperar 🌎 Las
          generaciones futuras merecen una educación que las prepare para
          desafíos que aún no conocemos. Al unirte a SRV, no solo estás
          eligiendo una educación superior; estás tomando una postura. Estás
          diciendo que creés en un mundo donde la educación es la clave para
          desbloquear un futuro más brillante para todos.
          <p />
          No te quedes al margen mientras otros cambian el mundo. Sé parte de
          esta revolución educativa. Sumáte a SRV y transforma tu mundo. Porque
          en SRV, no solo somos educación, somos revolución.
        </Typography>
        <motion.div
          initial={{ textShadow: '0 0 10px #fff' }}
          animate={{
            textShadow: [
              '0 0 10px #fff',
              '0 0 20px #f0f',
              '0 0 30px #f0f',
              '0 0 20px #f0f',
              '0 0 10px #fff',
            ],
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            style={{
              fontSize: 'calc(1.5rem + 1vw)',
              margin: '40px 0 20px',
              color: '#fafafa',
            }}
          >
            MIRA EL VIDEO COMPLETO A CONTINUACIÓN Y RECLAMA TU PREMIO
          </Typography>
        </motion.div>
        <Box mt={4} display="flex" justifyContent="center">
          <Video />
        </Box>

        {/* Formulario personalizado */}
        <Box mt={4} display="flex" justifyContent="center">
          <CustomForm />
        </Box>
      </div>
    </>
  );
}

export default LandingInfoProducto;
