import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fade from 'react-reveal/Fade';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';
import backgroundGif from '../images/UNIVERSO.optimized.svg';
import rightImage from '../images/SRV-TRANSPARENTE.optimized.svg';

function HeroSection() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const handleClick = () => {
    window.scrollTo({ top: 0 });
    navigate('/tienda/vida');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundGif})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <React.Fragment>
        <Fade>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: isMobile ? 2 : 0,
            }}
          >
            <LazyLoad height={200} offset={100} once>
              <img
                src={rightImage}
                alt="Inspirational"
                style={{
                  width: isMobile ? '80%' : '80%',
                  height: 'auto',
                  borderRadius: '10px',
                }}
              />
            </LazyLoad>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: isMobile ? 'center' : 'left',
              p: isMobile ? 2 : 10,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: 2,
                color: '#ffffff',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Transforma tu vida
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                color: '#ffffff',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Desarrolla tu potencial Humano
            </Typography>
            <Button
              onClick={() => handleClick()}
              variant="contained"
              color="secondary"
              size="large"
            >
              Empeza Ahora
            </Button>
          </Box>
        </Fade>
      </React.Fragment>
    </Box>
  );
}

export default HeroSection;
