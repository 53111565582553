import programacionImage from '../images/PROGRAMACION.png';
import backendJavascriptImage from '../images/BACKENDJS.png';
import javaScript from '../images/JAVASCRIPT.png';
import tallerDar from '../images/GALAXY1.png';
import tallerPc from '../images/SRV-PLANET.png';
import tallerCpp from '../images/SRV-PLANET2.png';

export const courses = [
  {
    description:
      'Iníciate en el mundo de la programación y aprende a construir aplicaciones impresionantes desde cero.',
    idName: 'javascript-desde-cero',
    image: javaScript,
    isActive: true,
    title: 'JavaScript : El Primer Paso hacia el EXITO',
  },
  {
    description:
      'Python, JavaScript, Automatizaciones, Bots de Trading, Bots de discord y muchos mas, todo en nuestro curso de Scripting',
    idName: 'scripting',
    image: programacionImage,
    isActive: true,
    title: 'Scripting : Tu Puerta de Entrada al Futuro Digital',
  },
  {
    description:
      'Adéntrate en el mundo del desarrollo Backend con JavaScript, aprendiendo a construir aplicaciones robustas, eficientes y escalables.',
    idName: 'backend-javascript',
    image: backendJavascriptImage,
    isActive: true,
    title: 'BackEnd en JavaScript : Domina el Desarrollo',
  },
];

export const talleres = [
  {
    description:
      'El taller D.A.R.(Desarrollo de Alto Rendimiento) fue creado con la idea de transimitir los secretos mas importantes del alto rendimiento en el mundo moderno, con practicas tanto tecnicas como blandas',
    idName: 'taller-dar',
    image: tallerDar,
    isActive: false,
    title: 'Taller D.A.R.',
  },
  {
    description:
      'P.C por sus siglas Programacion Conciente, es un taller que desentraña los secretos de la programacion',
    idName: 'taller-pc',
    image: tallerPc,
    isActive: false,
    title: 'Taller P.C.',
  },
  {
    description:
      'Codeando Mas y Mejor, es el taller por excelencia para el programador, donde se disfrutan de los secretos mas importantes de la programacion',
    idName: 'taller-dar',
    image: tallerCpp,
    isActive: false,
    title: 'Taller C++ ',
  },
];
