import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  MenuItem,
  Popper,
  Paper,
  ClickAwayListener,
  Grow,
  MenuList,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FaInstagram, FaTiktok, FaYoutube, FaDiscord } from 'react-icons/fa';
import logo from './images/SRV-LOGO-BLACK.png';

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('main');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopper, setOpenPopper] = useState(null);

  // const isMobile = useMediaQuery('sm');
  const isMobile = useMediaQuery('(max-width:700px)');
  const navigate = useNavigate();

  const handleNavigateAndScroll = id => {
    navigate(`/${id}`);
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
    setCurrentMenu('main');
  };

  const handleScrollToTopAndNavigate = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/');
  };

  const handleHover = (event, id) => {
    if (!isMobile) {
      setAnchorEl(event.currentTarget);
      setOpenPopper(id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopper(null);
  };

  const handleMenuClick = menu => {
    setDrawerOpen(false);
    navigate(`/${menu}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderMenuItems = menu => {
    const menuItems = {
      main: [
        { title: 'Blog', menu: 'blog' },
        { title: 'Nosotros', menu: 'nosotros' },
        { title: 'Tienda', menu: 'tienda' },
        { title: 'Comunidad', menu: 'comunidad' },
      ],
      blog: [
        {
          label: 'Mi Salud Mental',
          onClick: () => handleNavigateAndScroll('blog/salud-mental'),
        },
        {
          label: 'Mi Abundancia',
          onClick: () => handleNavigateAndScroll('blog/abundancia'),
        },
        {
          label: 'Mi Negocio',
          onClick: () => handleNavigateAndScroll('blog/profesion-negocios'),
        },
        {
          label: 'Mi Desarrollo Personal',
          onClick: () => handleNavigateAndScroll('blog/desarrollo-personal'),
        },
      ],
      nosotros: [
        {
          label: 'Historia',
          onClick: () => handleNavigateAndScroll('nosotros/sobre-srv'),
        },
        {
          label: 'Mision y Vision',
          onClick: () => handleNavigateAndScroll('nosotros/mision-vision'),
        },
        {
          label: 'Participa',
          onClick: () => handleNavigateAndScroll('nosotros/participa'),
        },
        {
          label: 'Historias de Éxito',
          onClick: () => handleNavigateAndScroll('nosotros/historias-de-exito'),
        },
        {
          label: 'Founders',
          onClick: () => handleNavigateAndScroll('../nosotros/profile'),
        },
      ],
      tienda: [
        {
          label: 'Todos los Productos',
          onClick: () => handleNavigateAndScroll('tienda/todos-los-productos'),
        },
        {
          label: 'Coaching de Negocios',
          onClick: () => handleNavigateAndScroll('tienda/negocios'),
        },
        {
          label: 'Coaching de Vida',
          onClick: () => handleNavigateAndScroll('tienda/vida'),
        },
        {
          label: 'Talleres',
          onClick: () => handleNavigateAndScroll('tienda/talleres'),
        },
      ],
      comunidad: [
        {
          label: 'Nuestros Streams',
          onClick: () => handleNavigateAndScroll('comunidad/streams'),
        },
        {
          label: '¿Queres Stremear?',
          onClick: () => handleNavigateAndScroll('comunidad/quiero-stremear'),
        },
      ],
    };

    if (menu === 'main') {
      return menuItems[menu].map(item => (
        <div
          onMouseEnter={e => handleHover(e, item.menu)}
          onMouseLeave={handleClose}
          key={item.menu}
          style={{ marginLeft: '1.5rem' }}
        >
          <Button
            style={{
              fontFamily: 'Ubuntu',
              color: '#000000',
              fontWeight: 'bold',
              textShadow: '0px 0px 8px rgba(255, 255, 255, 0.6)',
              margin: isMobile ? '5%' : '1%',
            }}
            aria-label={`Ir a ${item.title}`}
            endIcon={!isMobile ? <ArrowDropDownIcon /> : <></>}
            onClick={() => handleMenuClick(item.menu)}
          >
            {item.title}
          </Button>
          {!isMobile && (
            <Popper
              open={openPopper === item.menu}
              anchorEl={anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: 'center top' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={openPopper === item.menu}>
                        {menuItems[item.menu].map((subItem, index) => (
                          <MenuItem key={index} onClick={subItem.onClick}>
                            {subItem.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </div>
      ));
    }
  };

  const renderSocialLinks = () => (
    <div style={{ backgroundColor: 'black', marginBlockStart: 'auto' }}>
      {isMobile && (
        <a
          href="https://wa.link/3wbd8e"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: '1rem 1rem 0 0',
            color: '#ffffff',
            fontFamily: 'arial',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            textDecoration: 'none',
          }}
        >
          +54-11-6630-0591
        </a>
      )}
      <div
        className="social-links"
        style={{
          marginBottom: '10px',
          justifyContent: 'flex-start',
          padding: '1rem',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {!isMobile && (
          <a
            href="https://wa.link/3wbd8e"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              margin: '0 10px',
              color: '#ffffff',
              fontFamily: 'arial',
              textDecoration: 'none',
            }}
          >
            +54-11-6630-0591
          </a>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            href="https://www.instagram.com/wearesrv"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram
              size={30}
              style={{ margin: '0 10px', color: '#ffffff' }}
            />
          </a>
          <a
            href="https://www.tiktok.com/@wearesrv"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="TikTok"
          >
            <FaTiktok
              size={30}
              style={{ margin: '0 10px', color: '#ffffff' }}
            />
          </a>
          <a
            href="https://www.youtube.com/@weareSRV"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <FaYoutube
              size={30}
              style={{ margin: '0 10px', color: '#ffffff' }}
            />
          </a>
          <a
            href="https://discord.com/invite/QuAmDxrNMu"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Discord"
          >
            <FaDiscord
              size={30}
              style={{ margin: '0 10px', color: '#ffffff' }}
            />
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div style={{ width: '25px', height: isMobile ? '4rem' : '5rem' }} />
      <AppBar
        position="fixed"
        className="header-footer-bg"
        color="secondary"
        style={{ paddingBottom: '1rem' }}
      >
        {!isMobile && renderSocialLinks()}
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-start',
            }}
          >
            <img
              src={logo}
              alt="Logo SRV"
              style={{ height: '50px', cursor: 'pointer' }}
              onClick={handleScrollToTopAndNavigate}
            />
          </Box>
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ color: '#000000' }} />
            </IconButton>
          )}
          {!isMobile && renderMenuItems('main')}
        </Toolbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            '& .MuiDrawer-paper': {
              background: 'white',
              color: 'black',
            },
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
            role="presentation"
          >
            {renderMenuItems(currentMenu)}
            {renderSocialLinks()}
          </Box>
        </Drawer>
      </AppBar>

      {!isMobile && <Toolbar />}
    </div>
  );
}

export default Header;
