// src/Footer.js
import React from 'react';
import { Box, Typography, Link, Grid, IconButton } from '@mui/material';
import { FaInstagram, FaTiktok, FaYoutube, FaDiscord } from 'react-icons/fa';

import './Footer.css'; // Asegúrate de crear y ajustar el archivo CSS

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#000',
        color: '#fff',
        py: 4,
        padding: '1rem',
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={3} sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            SRV
          </Typography>
          <Typography variant="body2">
            Ciudad Autonoma de Buenos Aires CP1437 <br />
            Buenos Aires, Argentina
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">CONECTA CON NOSOTROS</Typography>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <IconButton
                href="https://www.instagram.com/wearesrv"
                color="inherit"
              >
                <FaInstagram />
              </IconButton>
              <IconButton
                href="https://www.tiktok.com/@wearesrv"
                color="inherit"
              >
                <FaTiktok />
              </IconButton>
              <IconButton
                href="https://discord.com/invite/QuAmDxrNMu"
                color="inherit"
              >
                <FaDiscord />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/@weareSRV"
                color="inherit"
              >
                <FaYoutube />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Mapa del Sitio
          </Typography>
          <Typography variant="body2">
            <Link href="/nosotros" color="inherit">
              Nosotros
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="/tienda" color="inherit">
              Nuestros Programas
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="/comunidad" color="inherit">
              Comunidad
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Carreras
          </Typography>
          <Typography variant="body2">
            <Link href="" color="inherit">
              Políticas de Privacidad
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="" color="inherit">
              Evaluación DISC
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="" color="inherit">
              Programa Enterprise
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            CONSULTAS DE MEDIOS
          </Typography>
          <Typography variant="body2">
            SRV Research International, Inc. tiene un departamento de medios
            dedicado. Los miembros de la prensa pueden contactarnos.
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            ATENCIÓN AL CLIENTE
          </Typography>
          <Typography variant="body2">
            Contacte al Soporte al Cliente para preguntas sobre sus productos,
            coaching o eventos.
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body2">
          © 2024 SRV Todos los derechos reservados.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
