import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Helmet } from 'react-helmet'; // Importa React Helmet para SEO
import './PlanNegocio.css'; // Asegúrate de crear este archivo CSS

function PlanNegocio() {
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [dni, setDni] = useState('');
  const [celular, setCelular] = useState('');
  const [modalidad, setModalidad] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !validateEmail(email) ||
      !nombre ||
      !apellido ||
      !dni ||
      !celular ||
      !modalidad
    ) {
      setFormError(true);
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSdNDhM-spqItSiFh3QBlnh7LqAOReqG-Tzrm66rh5P5Cy5dwQ/formResponse';
    const emailEntryId = 'entry.1750272805';
    const apellidoEntryId = 'entry.130616501';
    const nombreEntryId = 'entry.1069060505';
    const dniEntryId = 'entry.1281031995';
    const celularEntryId = 'entry.2067204794';
    const modalidadEntryId = 'entry.1385663608';
    const asociacionEntryId = 'entry.615450092';

    const formData = new FormData();
    formData.append(emailEntryId, email);
    formData.append(nombreEntryId, nombre);
    formData.append(apellidoEntryId, apellido);
    formData.append(dniEntryId, dni);
    formData.append(celularEntryId, celular);
    formData.append(modalidadEntryId, modalidad);
    formData.append(asociacionEntryId, 'SAAC');

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setEmail('');
      setNombre('');
      setApellido('');
      setDni('');
      setCelular('');
      setModalidad('');
      setEmailError(false);
      setFormError(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: '5%' }}>
      <Helmet>
        <title>Programa Educativo de JavaScript para SAAC - SRV 2024</title>
        <meta
          name="description"
          content="Únete a nuestro programa educativo de JavaScript para Scouts de Argentina y descubre cómo alcanzar tu máximo potencial en el desarrollo web. Empieza en julio 2024."
        />
        <meta
          name="keywords"
          content="curso de programación, JavaScript, desarrollo web, SRV, SAAC, aprendizaje personalizado"
        />
      </Helmet>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: '#FFFFFF', textAlign: 'center' }}
      >
        ¡Cómo Dominar JavaScript en Sólo 4 Semanas! ¡Incluso si Nunca
        Programaste!
      </Typography>
      <br />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'left' }}>
          <Typography
            variant="body1"
            style={{
              color: '#FFFFFF',
              marginBottom: '16px',
              textAlign: 'justify',
            }}
          >
            ¡Hola!
            <br />
            <br />
            ¿Cómo estás?
            <br />
            <br />
            En SRV estamos emocionados de anunciar el lanzamiento de nuestro
            nuevo programa educativo de JavaScript, diseñado específicamente
            para los Scouts de Argentina. 🚀
            <br />
            <br />
            Si siempre quisiste aprender a programar o conoces a alguien que
            esté interesado, esta es la oportunidad perfecta para sumarte a una
            experiencia de aprendizaje única y personalizada.
            <br />
            <br />
            <b>¿Por qué unirte a nuestro programa?</b>
            <br />
            🔹 <b>Aprendizaje Personalizado:</b> Adaptamos el contenido a tu
            ritmo y estilo, asegurando que cada estudiante tenga una experiencia
            enriquecedora y libre de estrés.
            <br />
            🔹 <b>Accesible y Flexible:</b> Ofrecemos modalidades intensivas y
            flexibles para que puedas elegir la que mejor se adapte a tus
            necesidades.
            <br />
            🔹 <b>Apoyo Constante:</b> Vas a contar con el acompañamiento de
            instructores experimentados y una comunidad de apoyo durante todo tu
            proceso de aprendizaje.
            <br />
            <br />
            <b>Fechas Importantes:</b>
            <br />
            <ul>
              <li>
                📅
                <b>Inscripciones:</b> Abiertas del 23 de junio al 10 de julio.
              </li>
              <li>
                📅
                <b>Inicio del Curso:</b>
                <ul>
                  <li>🚀Modalidad Intensiva: 15 de julio.</li>
                  <li>🚀Modalidad Flexible: 16 de julio.</li>
                </ul>
              </li>
            </ul>
            <br />
            <b>¡CUPOS LIMITADOS!</b>
            <br />
            <br />
            ¡No pierdas esta oportunidad! Asegurá tu lugar cuanto antes y
            desarrollá habilidades digitales esenciales.
            <br />
            <br />
            <b>Seguínos en Redes Sociales:</b>
            <br />
            📲{' '}
            <a
              href="https://linktr.ee/srveducation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00FFD1' }}
            >
              <b>SRV Education</b>
            </a>
            : Mantenete al día con todas nuestras novedades y eventos.
            <br />
            <br />
            Estamos revolucionando la educación, haciendo que el aprendizaje de
            habilidades digitales sea más accesible y personalizado que nunca.
            <br />
            <br />
            ¡Gracias por ayudarnos a difundir esta iniciativa! Juntos podemos
            marcar una gran diferencia. 🚀
            <br />
            <br />
            <b>
              "Empezá tu viaje en la programación y descubrí un mundo de
              posibilidades."
            </b>
            <br />
            <br />
            <b>¡Somos SRV, Somos Revolución!</b>
          </Typography>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '40%' }, textAlign: 'left' }}>
          <Card
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '16px' }}
          >
            <CardContent>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nombre"
                  label="Nombre"
                  name="nombre"
                  autoComplete="nombre"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="apellido"
                  label="Apellido"
                  name="apellido"
                  autoComplete="apellido"
                  value={apellido}
                  onChange={e => setApellido(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dni"
                  label="DNI"
                  name="dni"
                  autoComplete="dni"
                  value={dni}
                  onChange={e => setDni(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="celular"
                  label="Celular"
                  name="celular"
                  autoComplete="celular"
                  value={celular}
                  onChange={e => setCelular(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Dirección de correo electrónico"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError(!validateEmail(e.target.value));
                  }}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                  error={emailError}
                  helperText={
                    emailError
                      ? 'Por favor, ingrese un correo electrónico válido.'
                      : ''
                  }
                />
                <FormControl fullWidth margin="normal" required>
                  <InputLabel id="modalidad-label" style={{ color: '#FFFFFF' }}>
                    Modalidad
                  </InputLabel>
                  <Select
                    labelId="modalidad-label"
                    id="modalidad"
                    value={modalidad}
                    onChange={e => setModalidad(e.target.value)}
                    label="Modalidad"
                    style={{ color: '#FFFFFF' }}
                    inputProps={{ style: { color: '#FFFFFF' } }}
                  >
                    <MenuItem value="Intensiva">Intensiva</MenuItem>
                    <MenuItem value="Flexible">Flexible</MenuItem>
                  </Select>
                </FormControl>
                {formError && (
                  <Typography variant="body2" color="error" align="center">
                    Todos los campos son obligatorios y el correo electrónico
                    debe ser válido.
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#00FFD1',
                    color: '#000000',
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </div>
  );
}

export default PlanNegocio;
