import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './Categories.css';

function Section({ card, navigate }) {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.6,
  });

  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="card-mobile" onClick={() => navigate(card.path)}>
      <div
        ref={ref}
        className={`card-inner ${inView ? 'active' : ''}`}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <motion.div
          className="card-description"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={fadeInVariants}
          transition={{ duration: 0.6, ease: 'easeInOut' }}
        >
          <h2>{card.label}</h2>
          <h3>{card.description}</h3>
          <h3>Haz Click para Saber Más</h3>
        </motion.div>
        <div className={`card-front ${inView ? 'active' : ''}`}>
          <div className="image-container">
            <img src={card.image} alt={card.label} />
          </div>
          <h3>{card.label}</h3>
        </div>
      </div>
    </div>
  );
}

function Categories({ cards, title, originPath }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigate = path => {
    navigate(`/${originPath}/${path}`);
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <h1
        style={{
          textAlign: 'center',
          color: '#000000',
          fontSize: '36px',
          marginBottom: '20px',
        }}
      >
        {title}
      </h1>
      <div className="cards-container">
        {cards.map(card =>
          !isMobile ? (
            <motion.div
              className="card"
              key={card.path}
              onClick={() => handleNavigate(card.path)}
            >
              <div className="card-inner">
                <div className="card-front">
                  <div className="image-container">
                    <img src={card.image} alt={card.label} />
                  </div>
                  <h3>{card.label}</h3>
                </div>
                <div className="card-back">
                  <h2>{card.label}</h2>
                  <h3>{card.description}</h3>
                  <h3>Haz Click para Saber Más</h3>
                </div>
              </div>
            </motion.div>
          ) : (
            <Section card={card} navigate={handleNavigate} />
          )
        )}
      </div>
    </>
  );
}

export default Categories;
