import React, { useState } from 'react';
import gif1 from '../../images/1.gif';
import gif2 from '../../images/2.gif';
import gif3 from '../../images/3.gif';

const gifs = [gif1, gif2, gif3];

function GifSwitcher() {
  const [currentGifIndex, setCurrentGifIndex] = useState(0);

  const handleClick = () => {
    setCurrentGifIndex(prevIndex => (prevIndex + 1) % gifs.length);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        cursor: 'pointer',
        zIndex: 1000,
      }}
    >
      <img
        src={gifs[currentGifIndex]}
        alt={`Gif ${currentGifIndex + 1}`}
        style={{ width: '250px', height: '250px' }}
      />
    </div>
  );
}

export default GifSwitcher;
