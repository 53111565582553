import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  typography: {
    fontFamily: 'Ubuntu, sans-serif',
  },
  palette: {
    primary: {
      main: '#FF7318',
    },
    secondary: {
      main: '#D7A4FF',
    },
  },
});

function JavaScriptDesdeCero() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Aprende JavaScript Desde Cero - SRV</title>
        <meta
          name="description"
          content="Aprende JavaScript desde cero y comienza a trabajar como desarrollador web. Inscríbete ahora en nuestro curso intensivo y transforma tu vida profesional."
        />
        <meta
          name="keywords"
          content="curso JavaScript, aprender JavaScript, desarrollo web, trabajo remoto, freelance, SRV"
        />
      </Helmet>
      <Box
        sx={{
          color: 'white',
          backgroundColor: '#010613',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <Container maxWidth="md" sx={{ textAlign: 'center', padding: '20px' }}>
          <Typography
            fontSize="40px"
            sx={{
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            ¡APRENDE <span style={{ color: '#D7A4FF' }}>JAVA</span>
            <span style={{ color: 'white' }}>SCRIPT DESDE CERO</span>
          </Typography>
          <Typography
            fontSize="40px"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              marginTop: '20px',
            }}
          >
            EN POCAS HORAS Y COMIENZA A FACTURAR EN DÓLARES YA!
          </Typography>
          <Button
            onClick={() =>
              window.open('https://pay.hotmart.com/V91996078A', '_blank')
            }
            variant="contained"
            sx={{
              marginTop: '20px',
              backgroundColor: '#00FFFF',
              color: 'black',
            }}
          >
            COMPRAR AHORA
          </Button>
        </Container>

        <Container maxWidth="md" sx={{ textAlign: 'center', padding: '20px' }}>
          <Typography
            fontSize="30px"
            component="h2"
            sx={{ fontWeight: 'bold', color: '#D7A4FF' }}
          >
            CON <span style={{ color: '#fff' }}>JAVASCRIPT DESDE CERO</span>{' '}
            APRENDERÁS:
          </Typography>
          <Typography
            variant="body1"
            fontSize="30px"
            sx={{ marginTop: '20px', color: 'white' }}
          >
            Imagina tener la capacidad de crear sitios web interactivos y
            desarrollar aplicaciones impresionantes desde la comodidad de tu
            hogar. Con nuestro curso "Java Script desde Cero", te equiparemos
            con todas las habilidades necesarias para hacer realidad tus ideas y
            proyectos. En un mercado laboral donde la demanda de desarrolladores
            competentes sigue creciendo, dominar JavaScript puede significar la
            diferencia entre un empleo rutinario y una carrera vibrante y bien
            remunerada. Este curso no solo mejorará tus perspectivas de empleo,
            sino que te proporcionará las herramientas para lograr una
            realización profesional y financiera. ¿Estás listo para dar el paso
            hacia el éxito? No dejes pasar esta oportunidad de transformar tu
            vida. ¡Inscríbete hoy en 'Java Script desde Cero' y comienza a
            construir la carrera de tus sueños!
          </Typography>
          <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', color: '#D7A4FF' }}
            >
              1. Desarrollo de Aplicaciones Web Interactivas
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Crea sitios web dinámicos y aplicaciones web interactivas
              utilizando frameworks populares como React, Angular, o Vue.js.
              Estas aplicaciones pueden ir desde e-commerce y plataformas de
              educación en línea hasta herramientas de productividad y gestión
              de proyectos. La demanda de desarrolladores web sigue en aumento,
              y con JavaScript puedes crear soluciones personalizadas para
              negocios, atrayendo clientes y proyectos freelance bien
              remunerados.
            </Typography>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', marginTop: '20px', color: '#D7A4FF' }}
            >
              2. Desarrollo de Juegos para Móviles y Web
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              JavaScript, junto con motores de juegos como Phaser y Babylon.js,
              te permite crear juegos tanto para dispositivos móviles como para
              navegadores web. El mercado de los videojuegos es inmenso y sigue
              creciendo, ofreciendo múltiples vías de monetización, como compras
              dentro del juego, publicidad y modelos de suscripción. Crear
              juegos adictivos y atractivos puede convertirse en una fuente
              constante de ingresos.
            </Typography>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', marginTop: '20px', color: '#D7A4FF' }}
            >
              3. Desarrollo de extensiones y complementos para navegadores
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Desarrolla extensiones y complementos útiles para navegadores como
              Chrome y Firefox. Estas herramientas pueden mejorar la
              productividad, la seguridad, la accesibilidad, y más. Muchas
              extensiones exitosas generan ingresos a través de donaciones,
              ventas directas o suscripciones. Además, con la integración de
              APIs, puedes crear soluciones innovadoras que resuelvan problemas
              comunes de los usuarios de Internet, generando un flujo constante
              de ingresos pasivos.
            </Typography>
          </Box>
        </Container>

        <Container maxWidth="md" sx={{ textAlign: 'center', padding: '20px' }}>
          <Typography
            fontSize="30px"
            component="h2"
            sx={{ fontWeight: 'bold', color: '#00FF00' }}
          >
            Gana en Dólares Prestando Servicios con{' '}
            <span style={{ color: '#D7A4FF' }}>JAVASCRIPT DESDE CERO</span>
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginTop: '20px', color: 'white' }}
          >
            Descubre la forma más fácil y rápida de facturar como programador
            utilizando JavaScript Desde Cero.
          </Typography>
          <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
            <iframe
              style={{ width: '100%', maxWidth: '560px', height: '315px' }}
              src="https://www.youtube.com/embed/RYkXjlaBtt8"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
          <Button
            onClick={() =>
              window.open('https://pay.hotmart.com/V91996078A', '_blank')
            }
            variant="contained"
            color="secondary"
            sx={{ marginTop: '20px' }}
          >
            ¡QUIERO COMENZAR AHORA!
          </Button>
        </Container>

        <Container maxWidth="md" sx={{ textAlign: 'center', padding: '20px' }}>
          <Typography
            fontSize="30px"
            sx={{ fontWeight: 'bold', color: '#D7A4FF' }}
          >
            4 Razones por las que{' '}
            <span style={{ color: '#fff' }}>JAVASCRIPT DESDE CERO</span> es
            ideal para lograr tus sueños:
          </Typography>
          <Box sx={{ textAlign: 'left', marginTop: '20px' }}>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', color: '#D7A4FF' }}
            >
              1. Accesibilidad Total
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Diseñado para principiantes absolutos, nuestro curso te lleva de
              la mano desde los fundamentos hasta proyectos prácticos,
              garantizando que entiendas cada paso del proceso.
            </Typography>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', marginTop: '20px', color: '#D7A4FF' }}
            >
              2. Monetización Inmediata
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Con las habilidades que adquirirás, podrás desarrollar
              aplicaciones web, juegos y extensiones de navegador que están en
              alta demanda. Esto te permitirá generar ingresos de forma
              freelance, trabajar en proyectos globales y, lo más importante,
              empezar a facturar en dólares.{' '}
            </Typography>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', marginTop: '20px', color: '#D7A4FF' }}
            >
              3. Flexibilidad y Libertad
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Aprende a tu propio ritmo, desde cualquier lugar del mundo. La
              programación te ofrece la libertad de trabajar remotamente,
              permitiéndote balancear tu vida profesional y personal de manera
              óptima.
            </Typography>
            <Typography
              component="h3"
              sx={{ fontWeight: 'bold', marginTop: '20px', color: '#D7A4FF' }}
            >
              4. Soporte y Comunidad
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              No estarás solo en este viaje. Nuestro curso incluye acceso a una
              comunidad de aprendizaje donde podrás compartir tus progresos,
              resolver dudas y obtener feedback de otros estudiantes y
              profesionales.
            </Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              Con las habilidades que adquirirás, podrás desarrollar
              aplicaciones web, juegos y extensiones de navegador que están en
              alta demanda.
            </Typography>
          </Box>
          <Button
            onClick={() =>
              window.open('https://pay.hotmart.com/V91996078A', '_blank')
            }
            variant="contained"
            color="primary"
            sx={{ marginTop: '20px' }}
          >
            ¡LO QUIERO YA!
          </Button>
        </Container>

        <Container
          maxWidth="md"
          sx={{
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#000',
            color: 'white',
          }}
        >
          <Typography
            component="h2"
            sx={{ fontWeight: 'bold', color: '#D7A4FF' }}
          >
            Accede al contenido desde cualquier dispositivo por tan solo{' '}
            <span style={{ color: '#00FFFF' }}>USD37</span>
          </Typography>
          <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
            <ul style={{ paddingLeft: '20px' }}>
              <li>Tus datos personales son confidenciales</li>
              <li>Compra 100% segura</li>
              <li>
                Garantía de 15 dias, te devolvemos tu dinero sin
                cuestionamientos
              </li>
            </ul>
          </Box>
          <Button
            onClick={() =>
              window.open('https://pay.hotmart.com/V91996078A', '_blank')
            }
            variant="contained"
            sx={{
              marginTop: '20px',
              backgroundColor: '#00FFFF',
              color: 'black',
            }}
          >
            COMPRAR AHORA
          </Button>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default JavaScriptDesdeCero;
