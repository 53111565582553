import React from 'react';
import { useSpring, animated } from 'react-spring';

const LoadingSpinner = ({ titulos }) => {
  const props = useSpring({
    loop: true,
    to: { transform: 'rotate(360deg)' },
    from: { transform: 'rotate(0deg)' },
    config: { duration: 1000 },
  });

  return (
    <div
      style={{
        height: '85vh',
        alignContent: 'center',
        color: '#000',
        flexDirection: 'column',
      }}
    >
      <h1>Cargando {titulos}</h1>
      <animated.div
        style={{
          ...props,
          width: 50,
          height: 50,
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #000',
          borderRadius: '50%',
          margin: 'auto',
        }}
      />
    </div>
  );
};

export default LoadingSpinner;
