import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Helmet } from 'react-helmet';

const getDayOfWeek = dateString => {
  const daysOfWeek = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const [day, month, year] = dateString.split('/');
  const date = new Date(`${month}/${day}/${year}`);
  return daysOfWeek[date.getUTCDay()];
};

const rows = [
  {
    camada: '1',
    inicioInscripciones: '23/06',
    finInscripciones: '10/07',
    inicioClases: '15/07/2024',
    finClases: '12/08/2024',
    inicioProyecto: '12/08/2024',
    finProyecto: '16/08/2024',
    graduacion: '19/08/2024',
  },
  {
    camada: '2',
    inicioInscripciones: '15/08',
    finInscripciones: '30/08',
    inicioClases: '26/08/2024',
    finClases: '23/09/2024',
    inicioProyecto: '23/09/2024',
    finProyecto: '27/09/2024',
    graduacion: '30/09/2024',
  },
  {
    camada: '3',
    inicioInscripciones: '25/09',
    finInscripciones: '10/10',
    inicioClases: '07/10/2024',
    finClases: '04/11/2024',
    inicioProyecto: '04/11/2024',
    finProyecto: '08/11/2024',
    graduacion: '11/11/2024',
  },
];

function CalendarioJavaScript() {
  return (
    <div style={{ padding: '5%' }}>
      <Helmet>
        <title>Calendario de Inscripciones - JavaScript</title>
        <meta
          name="description"
          content="Calendario de las siguientes fechas de inscripciones para las camadas de JavaScript de SRV y Colegio del Salvador."
        />
        <meta
          name="keywords"
          content="calendario, inscripciones, JavaScript, SRV, Colegio del Salvador, programación, cursos"
        />
      </Helmet>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: '#FFFFFF', textAlign: 'center', padding: '5%' }}
      >
        Calendario de Inscripciones - JavaScript
      </Typography>
      <TableContainer
        component={Paper}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Camada
              </TableCell>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Inicio Clases
              </TableCell>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Fin Clases
              </TableCell>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Inicio Proyecto
              </TableCell>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Fin Proyecto
              </TableCell>
              <TableCell align="center" style={{ color: '#FFFFFF' }}>
                Graduación
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.camada}>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {row.camada}
                </TableCell>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {getDayOfWeek(row.inicioClases)} {row.inicioClases}
                </TableCell>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {getDayOfWeek(row.finClases)} {row.finClases}
                </TableCell>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {getDayOfWeek(row.inicioProyecto)} {row.inicioProyecto}
                </TableCell>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {getDayOfWeek(row.finProyecto)} {row.finProyecto}
                </TableCell>
                <TableCell align="center" style={{ color: '#FFFFFF' }}>
                  {getDayOfWeek(row.graduacion)} {row.graduacion}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CalendarioJavaScript;
