import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Container, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { motion } from 'framer-motion';
import quienesomos from './images/quienesSomos.png';
import wearesrv from './images/WEARESRV.COM.png';
import Footer from './Footer';

const streamsDomingo = [
  { id: 'kgLS30zvPUg', title: 'Video 1' },
  { id: 'u4uBL-K_IIA', title: 'Video 2' },
  { id: 'UALd_FAefT4', title: 'Video 3' },
];
const devAtNight = [
  { id: 'e8KaZ_OP03U', title: 'Video 1' },
  { id: 'dygiISLgkVI', title: 'Video 2' },
  { id: 'r8Les4aEhaM', title: 'Video 3' },
];

function Conocenos() {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
  };

  const handleNavigate = () => {
    navigate('/javascript-desde-cero');
    window.scrollTo({ top: 0 });
  };

  const handleEmailSubmit = async () => {
    if (!isEmailValid) {
      setEmailError(true);
      setMessage('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSf0Km7lwLaZNzVPxX9uWyEzGdYGJ88hoYu75-zFWqW3JtB0BQ/formResponse';
    const emailEntryId = 'entry.356532992'; // Reemplaza esto con el ID real de tu campo de correo electrónico

    const formData = new FormData();
    formData.append(emailEntryId, email);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setMessage('¡Gracias por suscribirte!');
      setIsEmailSent(true);
      setEmailError(false);
    } catch (error) {
      setMessage('Hubo un error al suscribirte. Por favor, intenta de nuevo.');
    }
  };

  return (
    <div style={{ backgroundColor: '#000' }}>
      <Helmet>
        <title>Conócenos - SRV</title>
        <meta
          name="description"
          content="Descubre cómo SRV puede ayudarte a impulsar tu vida con herramientas y contenidos accesibles."
        />
        <meta
          name="keywords"
          content="SRV, educación, tecnología, contenidos gratuitos, streams, desarrollo personal"
        />
      </Helmet>
      <Container
        maxWidth="md"
        style={{ textAlign: 'center', backgroundColor: '#000' }}
      >
        <Typography
          variant="h3"
          component="h1"
          fontSize="30px"
          gutterBottom
          style={{ margin: '20px 0' }}
        >
          Sabemos que la situación en general puede estar complicada. Por eso,
          en SRV buscamos darte una mano en lo que necesites, brindándote
          herramientas que pueden impulsar tu vida al nivel que tanto deseas.
          <br />
          Equipo SRV
        </Typography>

        <Box mt={4} display="flex" justifyContent="center">
          <img
            src={quienesomos}
            alt="Imagen de quienes somos"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>

        <motion.div
          initial={{ textShadow: '0 0 10px #fff' }}
          animate={{
            textShadow: [
              '0 0 10px #fff',
              '0 0 20px #f0f',
              '0 0 30px #f0f',
              '0 0 20px #f0f',
              '0 0 10px #fff',
            ],
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          <Typography
            variant="h2"
            component="h2"
            fontSize="30px"
            gutterBottom
            style={{ margin: '20px 0' }}
          >
            Existen principios y leyes básicos para lograr cumplir tus
            objetivos. Los siguientes contenidos y materiales de libre acceso
            son una puerta de entrada a ese cambio que puede mejorar tu vida y
            ayudar a aquellos que te importan.
          </Typography>
        </motion.div>
        <Box mt={4} display="flex" justifyContent="center">
          <img
            src={wearesrv}
            alt="We Are SRV"
            style={{ maxWidth: '66%', height: 'auto' }}
          />
        </Box>
        <Box mt={4}>
          <motion.div
            initial={{ textShadow: '0 0 10px #fff' }}
            animate={{
              textShadow: [
                '0 0 10px #fff',
                '0 0 20px #f0f',
                '0 0 30px #f0f',
                '0 0 20px #f0f',
                '0 0 10px #fff',
              ],
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <Typography
              variant="h3"
              component="h3"
              gutterBottom
              style={{ margin: '20px 0' }}
            >
              Nuestros Streams de los domingos - Momentos más de charla y
              reflexión dominguera
            </Typography>
          </motion.div>

          <Carousel>
            {streamsDomingo.map((video, i) => (
              <Box
                key={i}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.id}`}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    margin: '0 auto',
                    display: 'block',
                    borderRadius: '10px',
                  }}
                  aria-label={`Stream de domingo - ${video.title}`}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
        <Box mt={4}>
          <motion.div
            initial={{ textShadow: '0 0 10px #fff' }}
            animate={{
              textShadow: [
                '0 0 10px #fff',
                '0 0 20px #f0f',
                '0 0 30px #f0f',
                '0 0 20px #f0f',
                '0 0 10px #fff',
              ],
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <Typography
              variant="h3"
              component="h3"
              gutterBottom
              style={{ margin: '20px 0' }}
            >
              DevAtNight - Donde nos juntamos a la noche a programar y compartir
              juntos
            </Typography>
          </motion.div>
          <Carousel>
            {devAtNight.map((video, i) => (
              <Box
                key={i}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.id}`}
                  title={video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    margin: '0 auto',
                    display: 'block',
                    borderRadius: '10px',
                  }}
                  aria-label={`DevAtNight - ${video.title}`}
                />
              </Box>
            ))}
          </Carousel>
        </Box>

        <Box
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={3}
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '10px',
          }}
        >
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            style={{ color: '#fff', marginBottom: '20px' }}
          >
            ¿Quieres acceder al contenido gratuito? Déjanos tu correo
            electrónico y te lo brindamos:
          </Typography>
          <TextField
            label="Correo electrónico"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            style={{ margin: '10px', minWidth: '250px' }}
            error={emailError}
            helperText={
              emailError
                ? 'Por favor, ingresa un correo electrónico válido.'
                : ''
            }
          />
          <Button
            style={{
              fontSize: 'calc(1.2rem + 0.5vw)',
              padding: '20px 40px',
              backgroundColor: '#4caf50',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              cursor: isEmailValid ? 'pointer' : 'not-allowed',
              outline: 'none',
              margin: '10px',
              minWidth: '150px',
              opacity: isEmailValid ? 1 : 0.5,
            }}
            disabled={!isEmailValid}
            onClick={handleEmailSubmit}
            aria-label="Enviar correo electrónico"
          >
            Enviar
          </Button>
          <Button
            style={{
              fontSize: 'calc(1.2rem + 0.5vw)',
              padding: '20px 40px',
              backgroundColor: '#ff5722',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              cursor: isEmailSent ? 'pointer' : 'not-allowed',
              outline: 'none',
              margin: '10px',
              minWidth: '150px',
              opacity: isEmailSent ? 1 : 0.5,
            }}
            disabled={!isEmailSent}
            onClick={() =>
              (window.location.href =
                'https://drive.google.com/file/d/18_tndOsqJ72UPUIT5ooTTL8ZhjxI-ap-/view')
            }
            aria-label="Obtener guía gratuita"
          >
            Contenido de libre acceso
          </Button>
          {message && (
            <Typography
              variant="body1"
              style={{ color: emailError ? 'red' : 'green', marginTop: '20px' }}
            >
              {message}
            </Typography>
          )}
        </Box>

        <br />
        <br />
        <motion.div
          animate={{ x: [0, 10, -10, 10, -10, 0] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          whileHover={{ filter: 'brightness(1.5)' }}
        >
          <motion.button
            style={{
              fontSize: 'calc(1.2rem + 0.5vw)',
              padding: '20px 40px',
              backgroundColor: '#4caf50',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
              margin: '10px',
              minWidth: '150px',
            }}
            animate={{
              opacity: [1, 0.7, 1],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            aria-label="Si cambiaste de opinión"
            onClick={handleNavigate}
          >
            SI cambiaste de opinión
          </motion.button>
        </motion.div>
      </Container>
      <Footer />
    </div>
  );
}

export default Conocenos;
