import React from 'react';
import { Container, Typography, Card, Avatar } from '@mui/material';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';
import Fade from 'react-reveal/Fade';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialsSection.css';

const testimonials = [
  {
    name: 'Ayelen Salazar',
    feedback: 'Muy buenas las clases, son muy entretenidas',
    image: '../../../casco1.png',
  },
  {
    name: 'Lautaro Reynoso',
    feedback: 'Están buenas las clase y que sigan así que son entretenidas',
    image: '../../../cohete1.png',
  },
  {
    name: 'Enzo Puntieri',
    feedback:
      'Me copo mucho el modulo, me copan los trabajos que tenemos que hacer todos juntos, me es mas facil de entender las cosas practicandolas de esa forma. Realmente no tengo algo para decir para mejorar o de ese estilo, me parece que vienen muy bien las clases, son interesantes y entretenidas. ',
    image: '../../../enzo-puntieri.png',
  },
  {
    name: 'Franco Calvete',
    feedback:
      'Buena introducción a la programación, fue entretenido y fueron claros y atentos en cada explicación y respondiendo las consultas que nos surgían. Gracias!',
    image: '../../../franco-calvete.png',
  },
];

const testimonialSettings = {
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: '0',
      },
    },
  ],
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
};

function Section({ children }) {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 1,
  });

  return (
    <div ref={ref}>
      <Fade bottom when={inView}>
        {children}
      </Fade>
    </div>
  );
}

function TestimonialsSection() {
  return (
    <div className="testimonials-background">
      <Container sx={{ py: 8 }}>
        <Section>
          <Typography
            variant="h4"
            align="center"
            sx={{ mb: 4, color: 'white' }}
          >
            Historias de Exito
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ mb: 8, color: '#fafafa' }}
          >
            Personas que confiaron en nosotros
          </Typography>
        </Section>
        <Slider {...testimonialSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <Card className="testimonial-card">
                <Avatar
                  src={testimonial.image}
                  alt={testimonial.name}
                  sx={{ width: 100, height: 100, mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  {testimonial.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {testimonial.feedback}
                </Typography>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
}

export default TestimonialsSection;
