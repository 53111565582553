const abundanciaBlogs = [
  {
    description: 'Descubre cómo los ingresos pasivos pueden cambiar tu vida.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'el-poder-de-los-pasivos',
    title: 'El Poder de los Pasivos',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'inversiones-inteligentes',
    title: 'Inversiones Inteligentes',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'ahorro-efectivo',
    title: 'Ahorro Efectivo',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'planificacion-financiera',
    title: 'Planificación Financiera',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'libertad-financiera',
    title: 'Libertad Financiera',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'rendimientos-sostenibles',
    title: 'Rendimientos Sostenibles',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'diversificacion-de-activos',
    title: 'Diversificación de Activos',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'educacion-financiera',
    title: 'Educación Financiera',
  },
];

const desarrolloPersonalBlogs = [
  {
    description: 'Desarrolla una mentalidad fuerte y positiva.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'mentalidad',
    title: 'Mentalidad',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'motivacion-personal',
    title: 'Motivación Personal',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'resiliencia',
    title: 'Resiliencia',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'habitos-saludables',
    title: 'Hábitos Saludables',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'gestion-del-tiempo',
    title: 'Gestión del Tiempo',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'crecimiento-personal',
    title: 'Crecimiento Personal',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'desarrollo-de-habilidades',
    title: 'Desarrollo de Habilidades',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'autodisciplina',
    title: 'Autodisciplina',
  },
];

const profesionYNegociosBlogs = [
  {
    description: 'Consejos para llevar tu negocio al siguiente nivel.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'escala-tu-negocio',
    title: 'Escala Tu Negocio',
  },
  {
    description: 'Establece objetivos inteligentes para lograr el éxito.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'objetivos-smart',
    title: 'Objetivos Smart',
  },
  {
    description: 'Aprende a maximizar tu impacto en el entorno laboral.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'potencia-tu-impacto',
    title: 'Potencia tu impacto',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'marketing-digital',
    title: 'Marketing Digital',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'estrategias-de-ventas',
    title: 'Estrategias de Ventas',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'liderazgo-efectivo',
    title: 'Liderazgo Efectivo',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'innovacion-empresarial',
    title: 'Innovación Empresarial',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'gestion-de-proyectos',
    title: 'Gestión de Proyectos',
  },
];

const saludMentalBlogs = [
  {
    description:
      'Aprende a gestionar y dominar tus emociones para una vida más equilibrada.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'domina-tus-emociones',
    title: 'Domina Tus Emociones',
  },
  {
    description:
      'Descubre técnicas para manejar el estrés laboral de manera efectiva.',
    disabled: false,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'estres-laboral',
    title: 'Estrés Laboral',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'meditacion-mindfulness',
    title: 'Meditación y Mindfulness',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'tecnicas-de-relajacion',
    title: 'Técnicas de Relajación',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'manejo-de-ansiedad',
    title: 'Manejo de Ansiedad',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'autoestima-confianza',
    title: 'Autoestima y Confianza',
  },
  {
    description: 'Próximamente',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'terapias-alternativas',
    title: 'Terapias Alternativas',
  },
  {
    description: 'En construcción',
    disabled: true,
    hoverImage: '../../../cohete1.png',
    image: '../../../casco1.png',
    path: 'habilidades-sociales',
    title: 'Habilidades Sociales',
  },
];

const blogsInfo = [
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Mentalidad',
    id: 'mentalidad',
    title: 'CÓMO CAMBIAR TU MENTALIDAD: TRANSFORMACIÓN PERSONAL',
    subtitle:
      'APRENDE CÓMO CAMBIAR TU MENTALIDAD PARA ENCONTRAR LA VIDA QUE SIEMPRE HAS QUERIDO',
    section: {
      title: 'Desarrollo Personal',
      path: 'desarrollo-personal',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '20 min de lectura',
    postDate: 'Jul 6, 2024',
    image: '../../Imagenes/BANNER.gif',
    likes: '9K',
    comments: '186',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Escala tu negocio',
    id: 'escala-tu-negocio',
    title: 'ESCALA TU NEGOCIO: 11 MANERAS DE CONSTRUIR UN CRECIMIENTO DURADERO',
    subtitle: 'APRENDE CÓMO ESCALAR UN NEGOCIO DE MANERA SOSTENIBLE',
    section: {
      title: 'Profesion & Negocios',
      path: 'profesion-negocios',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '13 min de lectura',
    postDate: 'Jul 4, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '9.7K',
    comments: '174',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Objetivos SMART',
    id: 'objetivos-smart',
    title: 'LOGRA MÁS CON OBJETIVOS SMART',
    subtitle:
      'POR QUÉ ESTA TÉCNICA DE ESTABLECIMIENTO DE OBJETIVOS ES LA MEJOR FORMA DE ALCANZAR EL ÉXITO',
    section: {
      title: 'Profesion & Negocios',
      path: 'profesion-negocios',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '20 min de lectura',
    postDate: 'Jul 3, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '8.7K',
    comments: '74',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Potencia tu impacto',
    id: 'potencia-tu-impacto',
    title: 'POTENCIA TU IMPACTO: 10 CARACTERÍSTICAS QUE TODO LÍDER DEBE TENER',
    subtitle:
      'Descubre las Claves del Liderazgo Exitoso para Transformar tu Equipo y Alcanzar Nuevas Metas',
    section: {
      title: 'Profesion & Negocios',
      path: 'profesion-negocios',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '15 min de lectura',
    postDate: 'Jul 3, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '10K',
    comments: '192',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Domina tus emociones',
    id: 'domina-tus-emociones',
    title: 'DOMINA TUS EMOCIONES Y VIVE PLENAMENTE',
    subtitle:
      '6 PASOS PARA ALCANZAR LA MAESTRÍA EMOCIONAL QUE CAMBIARÁ TU VIDA',
    section: {
      title: 'Salud Mental',
      path: 'salud-mental',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '15 min de lectura',
    postDate: 'Jul 3, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '3K',
    comments: '137',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'Estrés laboral',
    id: 'estres-laboral',
    title: 'CÓMO LIDIAR CON EL ESTRÉS LABORAL EN LUGAR DE ESTRESARTE',
    subtitle: 'Sigue estos consejos para un trabajo sin ansiedad',
    section: {
      title: 'Salud Mental',
      path: 'salud-mental',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '12 min de lectura',
    postDate: 'Jul 4, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '6K',
    comments: '97',
  },
  {
    postImage: '../../../casco1.png',
    postImageHover: '../../../cohete1.png',
    relatedPostTitle: 'El poder de los pasivos',
    id: 'el-poder-de-los-pasivos',
    title: 'EL PODER DE LOS INGRESOS PASIVOS',
    subtitle:
      'APRENDE CÓMO AUMENTAR TUS INGRESOS SIN AUMENTAR TU CARGA DE TRABAJO',
    section: {
      title: 'Abundancia',
      path: 'abundancia',
    },
    author: {
      name: 'Fabio Gomez Ramirez',
      avatar: '../../Imagenes/FABIO.png',
      profileLink: 'profile/fabio-gomez',
      network: {
        instagram: 'https://www.instagram.com/fab333_',
      },
    },
    lectureTime: '6 min de lectura',
    postDate: 'Jul 5, 2024',
    image: '../../Imagenes/BANNER.gif', // Añade la ruta de la imagen correspondiente
    likes: '8.5K',
    comments: '210',
  },
];

const blogStructure = {
  id: '',
  title: '',
  subtitle: '',
  section: {
    title: '',
    path: '',
  },
  author: {
    name: '',
    avatar: '',
    profileLink: '',
    network: {
      instagram: '',
    },
  },
  lectureTime: '',
  postDate: '',
  image: '',
  likes: '',
  comments: '',
};

const categories = [
  {
    id: 'abundancia',
    title: 'Mi Abundancia',
    blogs: abundanciaBlogs,
  },
  {
    id: 'desarrollo-personal',
    title: 'Mi Desarrollo Personal',
    blogs: desarrolloPersonalBlogs,
  },
  {
    id: 'profesion-negocios',
    title: 'Mi Profesión y Negocio',
    blogs: profesionYNegociosBlogs,
  },
  {
    id: 'salud-mental',
    title: 'Mi Salud Mental',
    blogs: saludMentalBlogs,
  },
];

module.exports = {
  blogsInfo,
  blogStructure,
  categories,
};
