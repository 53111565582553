import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init('1453952745206164', options);

export const trackPageView = () => {
  ReactPixel.pageView();
};

export const trackEvent = (event, data) => {
  ReactPixel.track(event, data);
};
