import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import SRV from '../../images/SRV-simetrico.png';

function ContactForm({
  title,
  descriptionLabel,
  responseMessage,
  description,
}) {
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [consulta, setConsulta] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);

  const myLocation = location.pathname.split('/');

  const handleSubmit = async e => {
    e.preventDefault();
    const consultaType = myLocation[myLocation.length - 1] || '';
    if (!validateEmail(email)) {
      setEmailError(true);
      setMessage('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSdhbqtcFHejRB7OrCYN69N8fC2EBVRpRhoOfi-QWk3G8oPxUA/formResponse';
    const emailEntryId = 'entry.356532992'; // Reemplaza esto con el ID real de tu campo de correo electrónico
    const consultaEntryId = 'entry.936058976'; // Reemplaza esto con el ID real de tu campo de consulta
    const consultaEntryType = 'entry.915642647'; // Reemplaza esto con el ID real de tu campo de consulta

    const formData = new FormData();
    formData.append(emailEntryId, email);
    formData.append(consultaEntryId, consulta);
    formData.append(consultaEntryType, consultaType);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setMessage(responseMessage);
      setEmail('');
      setConsulta('');
      setEmailError(false);
    } catch (error) {
      setMessage('Hubo un error al suscribirte. Por favor, intenta de nuevo.');
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.root}>
          <div className={styles.clase1}>
            <h2 className={styles.title}>{title}</h2>
          </div>
          <div className={styles.clase2}>
            <img src={SRV} alt="SRV" className={styles.img} />
          </div>
          <div className={styles.md} />
          <div className={styles.grid}>
            <div className={styles.grid__left} />
            <div />
            <div className={styles.grid__right} />
          </div>
          <div className={styles.clase3}>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Dirección de correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setEmailError(!validateEmail(e.target.value));
                }}
                InputLabelProps={{ style: { color: '#fff' } }}
                InputProps={{
                  style: {
                    color: '#fff',
                    borderRadius: '16px',
                    border: '2px solid',
                    borderColor: '#fff',
                  },
                }}
                aria-label="Correo electrónico"
                error={emailError}
                helperText={
                  emailError
                    ? 'Por favor, ingresa un correo electrónico válido.'
                    : ''
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                multiline
                rows={4}
                id="consulta"
                label={descriptionLabel}
                name="consulta"
                autoComplete="consulta"
                value={consulta}
                onChange={e => setConsulta(e.target.value)}
                InputLabelProps={{ style: { color: '#fff' } }}
                InputProps={{
                  style: {
                    color: '#fff',
                    borderRadius: '16px',
                    border: '2px solid',
                    borderColor: '#fff',
                  },
                }}
                aria-label="Consulta"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                aria-label="Enviar formulario"
                color="secondary"
              >
                Enviar
              </Button>
              {message && (
                <Typography
                  style={{ color: '#fff' }}
                  variant="body2"
                  role="alert"
                >
                  {message}
                </Typography>
              )}
            </Box>
          </div>
          <div className={styles.clase4}>
            <p className={styles.paragraph}>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
