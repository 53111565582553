import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet'; // Importa React Helmet para SEO

function BackEndJavaScript() {
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [dni, setDni] = useState('');
  const [celular, setCelular] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateEmail(email) || !nombre || !apellido || !dni || !celular) {
      setFormError(true);
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLScvKYkaqk45lB6o3lNZiJwwxHbhoZf8ZmGdb6YeP36kdhff1A/formResponse';
    const emailEntryId = 'entry.1750272805'; // Reemplaza esto con el ID real de tu campo de correo electrónico
    const nombreEntryId = 'entry.1069060505';
    const apellidoEntryId = 'entry.130616501';
    const dniEntryId = 'entry.1281031995';
    const celularEntryId = 'entry.2067204794';

    const formData = new FormData();
    formData.append(emailEntryId, email);
    formData.append(nombreEntryId, nombre);
    formData.append(apellidoEntryId, apellido);
    formData.append(dniEntryId, dni);
    formData.append(celularEntryId, celular);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setEmail('');
      setNombre('');
      setApellido('');
      setDni('');
      setCelular('');
      setEmailError(false);
      setFormError(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const modulos = [
    {
      categoria: 'M1 - Fundamentos',
      temas: [
        {
          nombre: 'Introducción a la Programación y JavaScript',
          descripcion:
            'Programación, lenguajes y JavaScript. NodeJS, Git, GitHub, y Visual Studio Code',
        },
        {
          nombre: 'Variables y tipos de datos',
          descripcion:
            'Variables, Tipos de Datos, Operadores Aritmeticos, Comparación y Incremento y Decremento',
        },
        {
          nombre: 'Controladores de Flujo',
          descripcion:
            'Operadores Lógicos y Tabla de verdad. Diagramas de flujo. Estructura If, y Switch',
        },
        {
          nombre: 'Funciones y Tipos de Funciones',
          descripcion: '¿Qué es una función? y sus tipos.',
        },
        {
          nombre: 'Objeto Date y Math',
          descripcion: '¿Cómo funcionan? Ejemplos de uso.  | Funciones flechas',
        },
        {
          nombre: 'Estructura Repetitiva',
          descripcion: 'While, do while, for, for in, for of,',
        },
        {
          nombre: 'Estructura de Datos | Arrays',
          descripcion:
            'Arrays, Matrices. Métodos: push, pop, shift, unshift, concat, slice, filter.',
        },
        {
          nombre: 'Estructura de Datos | Arrays II',
          descripcion:
            'Arrays II, Métodos: includes, every, split, join, sort, indexOf, forEach y Map',
        },
        {
          nombre: 'Estructura de Datos | Objetos',
          descripcion: 'Objetos, Callbacks e Introducción a JSON',
        },
        {
          nombre: 'Introducción a las Clases',
          descripcion: 'Clases y uso de JSON',
        },
        {
          nombre: 'Promesas',
          descripcion:
            'Introducción, clase Promise, y simulación de procesos asincronicos',
        },
        {
          nombre: 'Promesas II',
          descripcion: 'Async Await, Axios, ejemplos reales',
        },
      ],
    },
    {
      categoria: 'M2 - Web Services',
      temas: [
        {
          nombre: 'Introducción a Node.js',
          descripcion:
            '¿Qué es Node.js y por qué es importante para el desarrollo backend? Configuración del entorno de desarrollo. Uso de Postman para peticiones a servidores',
        },
        {
          nombre: 'Gestión de Paquetes con NPM',
          descripcion:
            '¿Qué es NPM? Creación de un proyecto con npm init. Instalación y uso de paquetes.',
        },
        {
          nombre: 'Creando un Servidor Web con Express.js',
          descripcion:
            'Introducción a Express.js. Configuración de un servidor básico. Rutas y controladores.',
        },
        {
          nombre: 'Middleware en Express.js',
          descripcion:
            '¿Qué es un Middleware? Creación y uso de middleware para manejo de solicitudes.',
        },
      ],
    },
    {
      categoria: 'M3 - Database && Security',
      temas: [
        {
          nombre: 'Base de datos I',
          descripcion:
            'Introducción a bases de datos SQL (Modelos de datos) y consultas + Instalacion de Psql',
        },
        {
          nombre: 'Base de datos II',
          descripcion: 'Conexión a PostgreSQL con Node.js.',
        },
        { nombre: 'Base de datos III', descripcion: 'ORM, Sequelize Y Prisma' },
        {
          nombre: 'Autenticación y Seguridad',
          descripcion:
            'Manejo de autenticación en aplicaciones Node.js. Implementación de estrategias de autenticación con Passport.js.',
        },
        {
          nombre: 'Testing en Backend',
          descripcion:
            'Introducción a testing en Node.js. Uso de Mocha y Chai para pruebas unitarias y de integración.',
        },
        {
          nombre: 'Introducción a WebSocket',
          descripcion: 'Creando aplicaciones en tiempo real con WebSocket.',
        },
        {
          nombre: 'Manejo Avanzado de Errores',
          descripcion: 'Estrategias para el manejo centralizado de errores.',
        },
      ],
    },
    {
      categoria: 'M4 - Advanced Types',
      temas: [
        {
          nombre: 'TypeScript I',
          descripcion:
            'Beneficios de TypeScript en proyectos Node.js. Configuración de un proyecto Node.js con TypeScript.',
        },
        {
          nombre: 'TypeScript II',
          descripcion: 'Integracion de TypeScript con ExpressJS',
        },
        {
          nombre: 'TypeScript III + BD IV',
          descripcion:
            'Vamos a utilizar TypeORM para interactuar con APIS con typescript',
        },
        {
          nombre: 'Tipos Avanzados y Decoradores en TypeScript',
          descripcion:
            'Uso de tipos avanzados para mejorar el código. Introducción a decoradores y su utilidad en TypeScript.',
        },
      ],
    },
    {
      categoria: 'M5 - Cloud',
      temas: [
        {
          nombre: 'Introducción a Docker',
          descripcion:
            '¿Qué es Docker y por qué es importante para el desarrollo backend? Instalación de Docker y configuración inicial.',
        },
        {
          nombre: 'Contenedores Docker para Aplicaciones Node.js',
          descripcion:
            'Creación de imágenes Docker para aplicaciones Node.js. Manejo de contenedores, volúmenes y redes.',
        },
        {
          nombre: 'Docker Compose y Servicios Multi-Contenedor',
          descripcion:
            'Uso de Docker Compose para gestionar aplicaciones multi-servicio. Ejemplo práctico: Aplicación Node.js con PostgreSQL en contenedores.',
        },
        {
          nombre: 'Deploy de aplicaciones',
          descripcion:
            'Despliegue de aplicaciones en servidores, AWS, Heroku, Netlify',
        },
      ],
    },
  ];

  return (
    <div style={{ backgroundColor: '#010613', padding: '5%' }}>
      <Helmet>
        <title>Curso de Backend con JavaScript - SRV 2024</title>
        <meta
          name="description"
          content="Únete a nuestro curso innovador de Backend con JavaScript y descubre cómo alcanzar tu máximo potencial en el desarrollo web. Empieza en Octubre 2024."
        />
        <meta
          name="keywords"
          content="curso backend, JavaScript, desarrollo web, SRV, programación, aprendizaje personalizado"
        />
      </Helmet>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: '#FFFFFF', textAlign: 'center' }}
      >
        ¡Aprendé Desarrollo Backend con JavaScript en Sólo 3 Meses! ¡Incluso si
        No Tenés Experiencia Previa!
      </Typography>
      <br />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'left' }}>
          <Typography
            variant="body1"
            style={{
              color: '#FFFFFF',
              marginBottom: '16px',
              textAlign: 'justify',
            }}
          >
            ¡Hola! 👋
            <br />
            <br />
            ¡¿Cómo estás?!
            <br />
            <br />
            En SRV estamos lanzando un curso de programación innovador y
            invitamos a sumarte. 😉
            <br />
            <br />
            Si siempre quisiste aprender a programar o conoces a alguien
            interesado, esta es la oportunidad perfecta para comenzar tu viaje
            en el desarrollo de backend con JavaScript.
            <br />
            <br />
            <strong>¿Por qué sumarte a nuestro curso?</strong>
            <br />
            🔹 Aprendizaje Personalizado: Adaptamos el contenido a tu ritmo y
            estilo, asegurando que cada estudiante tenga una experiencia
            tranquila y enriquecedora.
            <br />
            🔹 Programa Innovador: Nuestro curso de backend con JavaScript está
            diseñado para brindarte las habilidades y conocimientos necesarios
            para destacarte en el mundo de la programación.
            <br />
            🔹 Accesibilidad y Flexibilidad: Aprendé desde cualquier lugar y en
            el horario que mejor te convenga. (Clases Grabadas)
            <br />
            <br />
            <strong>Datos Importantes</strong>
            <br />
            📅 Inicio del Curso: 28 de Octubre del 2024.
            <br />
            🚀 Clases: Lunes, Miercoles y Viernes, de 20:00 a 22:00 hora
            Argentina (UTC-3) <br />
            💸 Costo: U$D 957 (Aceptamos Débito, Crédito y PayPal).
            <br />
            🎉 Descuento Especial: Con transferencia bancaria tienes un 33% de
            descuento (Solo para Argentina y Criptomonedas.{' '}
            <a
              href="https://wa.link/j2bel4"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00FFD1' }}
            >
              ¡Contactanos para más detalles!
            </a>
            ).
            <br />
            <strong>Requisitos</strong>
            <br />
            <br />
            👽 Edad: Debes ser mayor de 18 años.
            <br />
            <br />
            <strong>Síguenos en Redes Sociales</strong>
            <br />
            📲{' '}
            <a
              href="https://linktr.ee/srveducation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00FFD1' }}
            >
              SRV Education
            </a>
            : Mantenete al día con todas nuestras novedades y eventos.
            <br />
            <br />
            Estamos revolucionando la educación haciendo que el aprendizaje de
            habilidades digitales sea más accesible y personalizado que nunca.
            <br />
            <br />
            <strong>¡Sumate a la Revolución Digital!</strong>
            <br />
            ¡No te pierdas esta oportunidad de transformar tu futuro! Asegurá tu
            lugar y da el primer paso hacia una carrera exitosa en el desarrollo
            de backend.
            <br />
            <br />
            ¡Gracias por ayudarnos a difundir esta iniciativa! Juntos podemos
            marcar una gran diferencia. 🚀
            <br />
            <br />
            <em>
              "Empezá tu viaje en la programación y descubrí un mundo de
              posibilidades."
            </em>
            <br />
            <br />
            ¡Somos SRV, Somos Revolución!
          </Typography>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '40%' }, textAlign: 'left' }}>
          <Card
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              borderRadius: '16px',
              border: '2px solid',
              borderColor: '#00FFD1',
            }}
          >
            <CardContent>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nombre"
                  label="Nombre"
                  name="nombre"
                  autoComplete="nombre"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="apellido"
                  label="Apellido"
                  name="apellido"
                  autoComplete="apellido"
                  value={apellido}
                  onChange={e => setApellido(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dni"
                  label="DNI"
                  name="dni"
                  autoComplete="dni"
                  value={dni}
                  onChange={e => setDni(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="celular"
                  label="Celular"
                  name="celular"
                  autoComplete="celular"
                  value={celular}
                  onChange={e => setCelular(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Dirección de correo electrónico"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError(!validateEmail(e.target.value));
                  }}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                  error={emailError}
                  helperText={
                    emailError
                      ? 'Por favor, ingrese un correo electrónico válido.'
                      : ''
                  }
                />
                {formError && (
                  <Typography variant="body2" color="error" align="center">
                    Todos los campos son obligatorios y el correo electrónico
                    debe ser válido.
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#00FFD1',
                    color: '#000000',
                    '&:hover': {
                      backgroundColor: '#00BFA5',
                    },
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ marginTop: 4 }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: '#FFFFFF', textAlign: 'center' }}
            >
              Contenido que veremos en la cursada
            </Typography>
            {modulos.map((modulo, index) => (
              <Accordion
                key={index}
                style={{
                  margin: '1%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '16px',
                  border: '2px solid',
                  borderColor: '#00FFD1',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography color="#ffffff" variant="h6">
                    {modulo.categoria}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {modulo.temas.map((tema, temaIndex) => (
                    <Box key={temaIndex} sx={{ marginBottom: 2 }}>
                      <Typography color="#ffffff" variant="subtitle1">
                        <strong>{tema.nombre}</strong>
                      </Typography>
                      <Typography color="#ffffff" variant="body2">
                        {tema.descripcion}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BackEndJavaScript;
