import React from 'react';
import Slider from 'react-slick';
import { Container, Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './streams.module.css';

const videos = [
  { title: 'Stream 5', id: 'mV7ESXO5DJU' },
  { title: 'Stream 6', id: 'u4uBL-K_IIA' },
  { title: 'Stream 7', id: 'UALd_FAefT4' },
  { title: 'Stream 8', id: '7bp3A1rPkpA' },
  { title: 'Stream 9', id: 'UALd_FAefT4' },
  { title: 'Stream 10', id: '0kb9-esMP6U' },
];

function VideoCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '50px', // Remove padding around the center slide
    focusOnSelect: true,
  };

  return (
    <div className={styles.root}>
      <h4 className={styles.title}>STREAMS DESTACADOS</h4>
      <Container className={styles.videoCarouselContainer}>
        <Slider {...settings} className={styles.carousel}>
          {videos.map(video => (
            <Box key={video.id} className={styles.videoWrapper}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video.id}`}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className={styles.videoIframe}
                aria-label={`Stream de domingo - ${video.title}`}
              />
            </Box>
          ))}
        </Slider>
      </Container>
    </div>
  );
}

export default VideoCarousel;
