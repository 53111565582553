import { profilesData } from '../../iterables/categoryCards';
import styles from './style.module.css';
import clsx from 'clsx';
import { useScroll, useTransform, motion } from 'framer-motion';
import { IconButton } from '@mui/material';
import {
  FaInstagram,
  FaDiscord,
  FaGithub,
  FaLinkedin,
  FaRegEnvelope,
} from 'react-icons/fa';

const AparecerAnimationOnScroll = ({ children, delay = 0 }) => {
  const { scrollY } = useScroll();
  const start = 500 + delay * 200;
  const end = start + 200;

  const opacity = useTransform(scrollY, [start, end], [0, 1]);
  const translateY = useTransform(scrollY, [start, end], [50, 0]);

  return (
    <motion.div style={{ opacity, translateY }} transition={{ duration: 0.5 }}>
      {children}
    </motion.div>
  );
};

const getProfile = profilePath => {
  // Asegúrate de que profilesData no esté vacío
  if (!profilesData || profilesData.length === 0) {
    console.error('profilesData is empty or not defined');
    return {}; // Retorna un objeto vacío en caso de que no haya datos
  }

  // Encontrar el perfil que coincide con el profilePath
  const profile = profilesData.find(card => card.data.path === profilePath);

  if (!profile) {
    console.error(`Profile with path ${profilePath} not found`);
  }

  return profile || {}; // Devuelve el perfil encontrado o un objeto vacío si no se encuentra
};

export default function Profile({ profilePath }) {
  const profile = getProfile(profilePath);
  const { data = {}, content = {}, networks = [] } = profile;

  const iconMap = {
    Instagram: <FaInstagram className={styles['button__contact']} />,
    GitHub: <FaGithub className={styles['button__contact']} />,
    LinkedIn: <FaLinkedin className={styles['button__contact']} />,
    Email: <FaRegEnvelope className={styles['button__contact']} />,
    Discord: <FaDiscord className={styles['button__contact']} />,
  };

  return (
    <div className={styles['profile__content']}>
      <section className={styles['profile__user']}>
        <div className={styles['img__container']}>
          <img className={styles['profile__img']} src={data.avatar} alt="" />
          <div className={styles['img__data']}>
            <h2 className={styles['user__name']}>{data.name}</h2>
            <span className={styles['user__description']}>{content.role}</span>
          </div>
        </div>
      </section>
      <section className={styles['profile__data']}>
        <h2 className={styles['profile__header']}>
          Datos sobre <span style={{ color: '#fa0' }}>{data.short_name}</span>
        </h2>
        <AparecerAnimationOnScroll delay={0}>
          <article
            className={clsx(
              styles['profile__article'],
              styles['history__article']
            )}
          >
            <h2>Historia</h2>
            <p className={styles['text__article']}>{content.history}</p>
          </article>
        </AparecerAnimationOnScroll>
        <AparecerAnimationOnScroll delay={2}>
          <article
            className={clsx(
              styles['profile__article'],
              styles['function__article']
            )}
          >
            <h2 className={styles['function__header']}>Función</h2>
            <p className={styles['text__article']}>{content.function}</p>
          </article>
        </AparecerAnimationOnScroll>
        <AparecerAnimationOnScroll delay={4}>
          <article
            className={clsx(
              styles['profile__article'],
              styles['hobbys__article']
            )}
          >
            <h2>Hobbys</h2>
            <p className={styles['text__article']}>{content.hobbys}</p>
          </article>
        </AparecerAnimationOnScroll>
      </section>
      <section className={styles['profile__contact']}>
        <div className={styles['contact__container']}>
          <h2 className={styles['contact__title']}>
            Sus <span style={{ color: '#fa0' }}>redes...</span>
          </h2>
          <ul className={styles['contact__list']}>
            {networks.map((network, index) => (
              <li key={index}>
                <IconButton
                  className={styles['contact__link']}
                  href={network.url}
                  color="inherit"
                  target="_blank"
                >
                  {iconMap[network.name]}
                </IconButton>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}
