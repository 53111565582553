import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet'; // Importa React Helmet para SEO

function BackEndJavaScript() {
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [dni, setDni] = useState('');
  const [celular, setCelular] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [modalidad, setModalidad] = useState('');
  const [formError, setFormError] = useState(false);

  const modulos = [
    {
      categoria: 'M1 - JavaScript',
      temas: [
        {
          nombre: 'Introducción a la Programación y JavaScript',
          descripcion:
            'Programación, lenguajes y JavaScript. NodeJS, Git, GitHub, y Visual Studio Code',
        },
        {
          nombre: 'Variables y tipos de datos',
          descripcion:
            'Variables, Tipos de Datos, Operadores Aritmeticos, Comparación y Incremento y Decremento',
        },
        {
          nombre: 'Controladores de Flujo',
          descripcion:
            'Operadores Lógicos y Tabla de verdad. Diagramas de flujo. Estructura If, y Switch',
        },
        {
          nombre: 'Funciones y Tipos de Funciones',
          descripcion: '¿Qué es una función? y sus tipos.',
        },
        {
          nombre: 'Objeto Date y Math',
          descripcion: '¿Cómo funcionan? Ejemplos de uso.  | Funciones flechas',
        },
        {
          nombre: 'Estructura Repetitiva',
          descripcion: 'While, do while, for, for in, for of,',
        },
        {
          nombre: 'Estructura de Datos | Arrays',
          descripcion:
            'Arrays, Matrices. Métodos: push, pop, shift, unshift, concat, slice, filter.',
        },
        {
          nombre: 'Estructura de Datos | Arrays II',
          descripcion:
            'Arrays II, Métodos: includes, every, split, join, sort, indexOf, forEach y Map',
        },
        {
          nombre: 'Estructura de Datos | Objetos',
          descripcion: 'Objetos, Callbacks e Introducción a JSON',
        },
        {
          nombre: 'Introducción a las Clases',
          descripcion: 'Clases y uso de JSON',
        },
        {
          nombre: 'Promesas',
          descripcion:
            'Introducción, clase Promise, y simulación de procesos asincronicos',
        },
        {
          nombre: 'Promesas II',
          descripcion:
            'Async Await, Axios, ejemplos reales y presentación del TP',
        },
      ],
    },
    {
      categoria: 'M2 - Python',
      temas: [
        {
          nombre: 'Introducción a Python',
          descripcion:
            '¿Qué es Python? Instalación | Hola Mundo, Variables, Tipos de Datos, Controladores de Flujo, Funciones y Bucles',
        },
        {
          nombre: 'Estructuras de Datos en Python',
          descripcion: 'Arrays, Objetos, Clases',
        },
        {
          nombre: 'REPASO - CHILL',
          descripcion:
            'Repaso elegido por los estudiantes, tópicos interesantes, motivación, acompañamiento, juegos.',
        },
        {
          nombre: 'Gestion de errores y archivos',
          descripcion: 'Estructura Try-Except, y manipulación de archivos',
        },
        {
          nombre: 'Promesas en Python',
          descripcion: 'Módulo asyncio | Bot de Discord I',
        },
        {
          nombre: 'Aplicaciones con Interfaz',
          descripcion: 'Tkinter',
        },
      ],
    },
    {
      categoria: 'M3 - Google AppScript',
      temas: [
        {
          nombre: 'Apps Script | Google Docs',
          descripcion:
            '¿Qué es? Uso de este | Crear documentos, modificarlos, y analizarlos',
        },
        {
          nombre: 'Google Sheets',
          descripcion: 'Lectura y Escritura de Celdas | Creación de funciones',
        },
        {
          nombre: 'Gmail, Drive y Google Calendar',
          descripcion: 'Envío de mails, uso de imágenes, eventos, reuniones',
        },
      ],
    },
  ];

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !validateEmail(email) ||
      !nombre ||
      !apellido ||
      !dni ||
      !celular ||
      !modalidad
    ) {
      setFormError(true);
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSewnEVGVQuCU8ol9FnH322tqiZIwqbgEhK_wpT7sZVkAAnSnw/formResponse';
    const emailEntryId = 'entry.1750272805';
    const apellidoEntryId = 'entry.130616501';
    const nombreEntryId = 'entry.1069060505';
    const dniEntryId = 'entry.1281031995';
    const celularEntryId = 'entry.2067204794';
    const cohorteEntryId = 'entry.75649520';

    const formData = new FormData();
    formData.append(emailEntryId, email);
    formData.append(nombreEntryId, nombre);
    formData.append(apellidoEntryId, apellido);
    formData.append(dniEntryId, dni);
    formData.append(celularEntryId, celular);
    formData.append(cohorteEntryId, modalidad);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setEmail('');
      setNombre('');
      setApellido('');
      setDni('');
      setCelular('');
      setModalidad('');
      setEmailError(false);
      setFormError(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div style={{ backgroundColor: '#010613', padding: '5%' }}>
      <Helmet>
        <title>Curso de Scripting con JavaScript - SRV 2024</title>
        <meta
          name="description"
          content="Únete a nuestro curso innovador de Backend con JavaScript y descubre cómo alcanzar tu máximo potencial en el desarrollo web. Empieza en agosto 2024."
        />
        <meta
          name="keywords"
          content="curso backend, JavaScript, desarrollo web, SRV, programación, aprendizaje personalizado"
        />
      </Helmet>
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: '#FFFFFF', textAlign: 'center' }}
      >
        SCRIPTING: 3 Cursos por el PRECIO de UNO, Python, JavaScript, Appscript, Y MUCHO MAS
      </Typography>
      <br />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'left' }}>
          <Typography
            variant="body1"
            style={{
              color: '#FFFFFF',
              marginBottom: '16px',
              textAlign: 'justify',
            }}
          >
            ¡Hola! 👋
            <br />
            <br />
            ¡¿Cómo estás?!
            <br />
            <br />
            En SRV estamos lanzando un curso de programación innovador y
            invitamos a sumarte. 😉
            <br />
            <br />
            Si siempre quisiste aprender a programar o conoces a alguien
            interesado, esta es la oportunidad perfecta para comenzar tu viaje
            en el desarrollo de backend con JavaScript.
            <br />
            <br />
            <strong>¿Por qué sumarte a nuestro curso?</strong>
            <br />
            🔹 Aprendizaje Personalizado: Adaptamos el contenido a tu ritmo y
            estilo, asegurando que cada estudiante tenga una experiencia
            tranquila y enriquecedora.
            <br />
            🔹 Programa Innovador: Nuestro curso de Scripting está diseñado para
            brindarte las habilidades y conocimientos necesarios para destacarte
            en el mundo de la programación.
            <br />
            🔹 Accesibilidad y Flexibilidad: Aprendé desde cualquier lugar y en
            el horario que mejor te convenga.
            <br />
            <br />
            <strong>Datos Importantes</strong>
            <br />
            📅 Inicio del Curso:
            <br />
            🔹 Cohorte 1: <b>30 de Septiembre del 2024.</b>
            <br />
            🔹 Cohorte 2: <b>1 de Octubre del 2024.</b>
            <br />
            🔹 Cohorte 3: <b>2 de Octubre del 2024.</b>
            <br />
            💸 Costo (70% de descuento, por tiempo limitado):
            <br />
            🔹 ANTES: <b style={{ textDecoration: "line-through" }}>$766.000 Pesos Argentinos</b>
            <br />
            🔹 AHORA: $153.200 Pesos Argentinos
            <br />
            🎉 Descuento Especial: Con transferencia bancaria tienes un 33% de
            descuento (Solo para Argentina y Criptomonedas.{' '}
            <a
              href="https://wa.link/j2bel4"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00FFD1' }}
            >
              ¡Contactanos para más detalles!
            </a>
            ).
            <br />
            <strong>Requisitos</strong>
            <br />
            <br />
            👽 Edad: Debes ser mayor de 18 años.
            <br />
            <br />
            <strong>Síguenos en Redes Sociales</strong>
            <br />
            📲{' '}
            <a
              href="https://linktr.ee/srveducation"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#00FFD1' }}
            >
              SRV Education
            </a>
            : Mantenete al día con todas nuestras novedades y eventos.
            <br />
            <br />
            Estamos revolucionando la educación haciendo que el aprendizaje de
            habilidades digitales sea más accesible y personalizado que nunca.
            <br />
            <br />
            <strong>¡Sumate a la Revolución Digital!</strong>
            <br />
            ¡No te pierdas esta oportunidad de transformar tu futuro! Asegurá tu
            lugar y da el primer paso hacia una carrera exitosa en el desarrollo
            de backend.
            <br />
            <br />
            ¡Gracias por ayudarnos a difundir esta iniciativa! Juntos podemos
            marcar una gran diferencia. 🚀
            <br />
            <br />
            <em>
              "Empezá tu viaje en la programación y descubrí un mundo de
              posibilidades."
            </em>
            <br />
            <br />
            ¡Somos SRV, Somos Revolución!
          </Typography>
        </Box>
        <Box sx={{ width: { xs: '100%', md: '40%' }, textAlign: 'left' }}>
          <Card
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              borderRadius: '16px',
              border: '2px solid',
              borderColor: '#00FFD1',
            }}
          >
            <CardContent>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nombre"
                  label="Nombre"
                  name="nombre"
                  autoComplete="nombre"
                  value={nombre}
                  onChange={e => setNombre(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="apellido"
                  label="Apellido"
                  name="apellido"
                  autoComplete="apellido"
                  value={apellido}
                  onChange={e => setApellido(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="dni"
                  label="DNI"
                  name="dni"
                  autoComplete="dni"
                  value={dni}
                  onChange={e => setDni(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="celular"
                  label="Celular"
                  name="celular"
                  autoComplete="celular"
                  value={celular}
                  onChange={e => setCelular(e.target.value)}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Dirección de correo electrónico"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                    setEmailError(!validateEmail(e.target.value));
                  }}
                  InputLabelProps={{ style: { color: '#FFFFFF' } }}
                  InputProps={{ style: { color: '#FFFFFF' } }}
                  error={emailError}
                  helperText={
                    emailError
                      ? 'Por favor, ingrese un correo electrónico válido.'
                      : ''
                  }
                />
                <FormControl fullWidth margin="normal" required>
                  <InputLabel id="modalidad-label" style={{ color: '#FFFFFF' }}>
                    Modalidad(Cohorte)
                  </InputLabel>
                  <Select
                    labelId="modalidad-label"
                    id="modalidad"
                    value={modalidad}
                    onChange={e => setModalidad(e.target.value)}
                    label="Modalidad"
                    style={{ color: '#FFFFFF' }}
                    inputProps={{ style: { color: '#FFFFFF' } }}
                  >
                    <MenuItem value="1">
                      Cohorte 1: Lunes y Jueves 19:00 - 21:00 ARG
                    </MenuItem>
                    <MenuItem value="2">
                      Cohorte 2: Martes y Viernes 19:00 - 21:00 ARG
                    </MenuItem>
                    <MenuItem value="3">
                      Cohorte 3: Miercoles y Sabado 19:00 - 21:00 ARG
                    </MenuItem>
                  </Select>
                </FormControl>
                {formError && (
                  <Typography variant="body2" color="error" align="center">
                    Todos los campos son obligatorios y el correo electrónico
                    debe ser válido.
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: '#00FFD1',
                    color: '#000000',
                    '&:hover': {
                      backgroundColor: '#00BFA5',
                    },
                  }}
                >
                  Enviar
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Box sx={{ marginTop: 4 }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ color: '#FFFFFF', textAlign: 'center' }}
            >
              Contenido que veremos en la cursada
            </Typography>
            {modulos.map((modulo, index) => (
              <Accordion
                key={index}
                style={{
                  margin: '1%',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '16px',
                  border: '2px solid',
                  borderColor: '#00FFD1',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography color="#ffffff" variant="h6">
                    {modulo.categoria}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {modulo.temas.map((tema, temaIndex) => (
                    <Box key={temaIndex} sx={{ marginBottom: 2 }}>
                      <Typography color="#ffffff" variant="subtitle1">
                        <strong>{tema.nombre}</strong>
                      </Typography>
                      <Typography color="#ffffff" variant="body2">
                        {tema.descripcion}
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BackEndJavaScript;
