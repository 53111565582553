import React from 'react';
import './PlanNegocio.css'; // Asegúrate de crear este archivo CSS

function PlanNegocio() {
  return (
    <div className="plan-negocio-container">
      <div className="plan-negocio-content">
        <div className="logos-container">
          <a
            href="https://www.wearesrv.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://wearesrv.com/static/media/SRV-white.8810023aed882dd73ddd.png"
              alt="Logo SRV"
              className="logo-srv"
            />
          </a>
        </div>

        <h1>Proyecto Integrador - Programa Educativo de JavaScript</h1>

        <h2>Información General del Curso:</h2>
        <p>
          <b>Programa Educativo de JavaScript</b>
        </p>
        <ul>
          <li>
            <b>-</b> Inscripciones abiertas del 23 de junio al 10 de julio.
          </li>
          <li>
            <b>-</b> Máximo total de 100 cupos. Si se alcanzan los 100 cupos, se
            liberarán 30 cupos adicionales.
          </li>
        </ul>

        <h2>Objetivos del Curso de JavaScript:</h2>
        <ul>
          <li>
            <b>-</b> Adquirir conocimientos y herramientas de programación de
            manera clara.
          </li>
          <li>
            <b>-</b> Comprender de manera lógica cada proceso para aplicarlos
            correctamente.
          </li>
          <li>
            <b>-</b> Fomentar un ambiente sano de trabajo para el crecimiento
            personal y grupal.
          </li>
          <li>
            <b>-</b> Brindar un seguimiento personal a los estudiantes durante
            toda su trayectoria.
          </li>
        </ul>

        <h2>Propósito de los Instructores:</h2>
        <ul>
          <li>
            <b>-</b> Generar un buen ambiente educativo fomentando
            predisposición, práctica, trabajo en equipo y participación activa.
          </li>
          <li>
            <b>-</b> Mantener una comunicación asertiva entre instructor y
            estudiante.
          </li>
          <li>
            <b>-</b> Alentar la curiosidad y el uso de nuevas tecnologías de
            manera lúdica.
          </li>
        </ul>

        <h2>Criterios de Cursada:</h2>
        <ul>
          <li>
            <b>-</b> Enseñar el uso óptimo de ChatGPT sin dejar de lado la
            comprensión de los procesos.
          </li>
          <li>
            <b>-</b> Completar y entregar todos los mini-proyectos para recibir
            la certificación del curso.
          </li>
        </ul>

        <h2>Contenido del Curso:</h2>
        <ul>
          <li>
            <b>-</b> Introducción a la Programación y JavaScript
          </li>
          <li>
            <b>-</b> Variables y Tipos de Datos
          </li>
          <li>
            <b>-</b> Controladores de Flujo
          </li>
          <li>
            <b>-</b> Funciones y Tipos de Funciones
          </li>
          <li>
            <b>-</b> Objeto Date y Math
          </li>
          <li>
            <b>-</b> Estructura Repetitiva
          </li>
          <li>
            <b>-</b> Estructura de Datos | Arrays
          </li>
          <li>
            <b>-</b> Estructura de Datos | Arrays II
          </li>
          <li>
            <b>-</b> Estructura de Datos | Objetos
          </li>
          <li>
            <b>-</b> Introducción a las Clases
          </li>
          <li>
            <b>-</b> Promesas
          </li>
          <li>
            <b>-</b> Promesas II
          </li>
        </ul>

        <h2>Modalidades del Curso:</h2>

        <h3>Modalidad Intensiva:</h3>
        <ul>
          <li>
            <b>-</b> <b>Duración:</b> 12 clases, 2 horas por clase, 3 clases por
            semana, duración total + Proyectos: 1 mes y 1 semana, máximo de
            alumnos: 50.
          </li>
          <li>
            <b>-</b> <b>Fechas:</b>
          </li>
          <ul>
            <li>
              <b>-</b> Inicio de clases: 15/07
            </li>
            <li>
              <b>-</b> Fin de clases: 09/08
            </li>
            <li>
              <b>-</b> Semana de Proyecto: 12/08 - 16/08
            </li>
            <li>
              <b>-</b> Semana de Entrega de certificados y ceremonia de
              graduación: 19/08 - 23/08
            </li>
          </ul>
        </ul>

        <h3>Modalidad Flexible:</h3>
        <ul>
          <li>
            <b>-</b> <b>Duración:</b> 12 clases, 2 horas por clase, 2 clases por
            semana, duración total + Proyectos: 2 meses, máximo de alumnos: 50.
          </li>
          <li>
            <b>-</b> <b>Fechas:</b>
          </li>
          <ul>
            <li>
              <b>-</b> Inicio de clases: 16/07
            </li>
            <li>
              <b>-</b> Fin de clases: 27/08
            </li>
            <li>
              <b>-</b> Semana de Proyecto: 02/09 - 06/09
            </li>
            <li>
              <b>-</b> Semana de Entrega de certificados y ceremonia de
              graduación: 09/09 - 13/09
            </li>
          </ul>
        </ul>

        <h2>Estructura de las Clases:</h2>
        <ul>
          <li>
            <b>-</b> <b>0:00:</b> Introducción / Espera
          </li>
          <li>
            <b>-</b> <b>0:15:</b> Contenido Teórico
          </li>
          <li>
            <b>-</b> <b>1:00:</b> Pausa Activa anti estrés
          </li>
          <li>
            <b>-</b> <b>1:15:</b> Contenido Práctico
          </li>
          <li>
            <b>-</b> <b>1:50:</b> Cierre
          </li>
          <li>
            <b>-</b> <b>2:00:</b> Feedback de la clase
          </li>
        </ul>

        <h2>Calendario de Clases - Modalidad Intensiva:</h2>
        <ul>
          <li>
            <b>-</b> 15/07: Introducción a la Programación y JavaScript
          </li>
          <li>
            <b>-</b> 17/07: Variables y tipos de datos
          </li>
          <li>
            <b>-</b> 19/07: Controladores de Flujo
          </li>
          <li>
            <b>-</b> 22/07: Funciones y Tipos de Funciones
          </li>
          <li>
            <b>-</b> 24/07: Objeto Date y Math
          </li>
          <li>
            <b>-</b> 26/07: Estructura Repetitiva
          </li>
          <li>
            <b>-</b> 29/07: Clase Extra (Elección de los alumnos)
          </li>
          <li>
            <b>-</b> 31/07: Estructura de Datos | Arrays
          </li>
          <li>
            <b>-</b> 02/08: Estructura de Datos | Arrays II
          </li>
          <li>
            <b>-</b> 05/08: Estructura de Datos | Objetos
          </li>
          <li>
            <b>-</b> 07/08: Introducción a las Clases
          </li>
          <li>
            <b>-</b> 09/08: Promesas
          </li>
          <li>
            <b>-</b> 12/08: Promesas II y presentación del TP
          </li>
        </ul>

        <h2>Calendario de Clases - Modalidad Flexible:</h2>
        <ul>
          <li>
            <b>-</b> 16/07: Introducción a la Programación y JavaScript
          </li>
          <li>
            <b>-</b> 18/07: Variables y tipos de datos
          </li>
          <li>
            <b>-</b> 23/07: Controladores de Flujo
          </li>
          <li>
            <b>-</b> 25/07: Funciones y Tipos de Funciones
          </li>
          <li>
            <b>-</b> 30/07: Objeto Date y Math
          </li>
          <li>
            <b>-</b> 01/08: Estructura Repetitiva
          </li>
          <li>
            <b>-</b> 06/08: Clase Extra (Elección de los alumnos)
          </li>
          <li>
            <b>-</b> 08/08: Estructura de Datos | Arrays
          </li>
          <li>
            <b>-</b> 13/08: Estructura de Datos | Arrays II
          </li>
          <li>
            <b>-</b> 15/08: Estructura de Datos | Objetos
          </li>
          <li>
            <b>-</b> 20/08: Introducción a las Clases
          </li>
          <li>
            <b>-</b> 22/08: Promesas
          </li>
          <li>
            <b>-</b> 27/08: Promesas II y presentación del TP
          </li>
        </ul>

        <h2>Calendarios de las Siguientes Camadas:</h2>

        <h3>Modalidad Intensiva:</h3>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Camada</th>
                <th>Inicio Clases</th>
                <th>Fin Clases</th>
                <th>Inicio Proyecto</th>
                <th>Fin Proyecto</th>
                <th>Graduación</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>15/07</td>
                <td>12/08</td>
                <td>12/08</td>
                <td>16/08</td>
                <td>19/08</td>
              </tr>
              <tr>
                <td>2</td>
                <td>26/08</td>
                <td>23/09</td>
                <td>23/09</td>
                <td>27/09</td>
                <td>30/09</td>
              </tr>
              <tr>
                <td>3</td>
                <td>07/10</td>
                <td>04/11</td>
                <td>04/11</td>
                <td>08/11</td>
                <td>11/11</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3>Modalidad Flexible:</h3>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Camada</th>
                <th>Inicio Clases</th>
                <th>Fin Clases</th>
                <th>Inicio Proyecto</th>
                <th>Fin Proyecto</th>
                <th>Graduación</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>16/07</td>
                <td>27/08</td>
                <td>27/08</td>
                <td>03/09</td>
                <td>04/09</td>
              </tr>
              <tr>
                <td>2</td>
                <td>10/09</td>
                <td>22/10</td>
                <td>22/10</td>
                <td>29/10</td>
                <td>30/10</td>
              </tr>
              <tr>
                <td>3</td>
                <td>05/11</td>
                <td>17/12</td>
                <td>17/12</td>
                <td>24/12</td>
                <td>25/12</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2>Estrategia de Inscripción:</h2>

        <h3>Período de Inscripción:</h3>
        <ul>
          <li>
            <b>-</b> Hasta el 10 de julio estarán abiertas las inscripciones.
          </li>
          <li>
            <b>-</b> Durante este período, se realizaran campañas de marketing
            para la captacion de inscriptos (Estrategia a definir).
          </li>
        </ul>

        <h3>Proceso de Confirmación de Inscripción:</h3>
        <ul>
          <li>
            <b>-</b> Las Inscripciones se realizaran por el siguiente Link{' '}
            <a
              href="https://www.wearesrv.com/salvador/javascript/inscripcion"
              style={{ color: '#00FFD1' }}
            >
              INSCRIPCION
            </a>
          </li>
          <li>
            <b>-</b> Los inscriptos recibirán un email de confirmación.
          </li>
          <li>
            <b>-</b> Los primeros 130 inscriptos tendrán asegurado su lugar en
            el curso.
          </li>
          <li>
            <b>-</b> A partir del inscripto N° 131, se enviará un email
            indicando que no hay cupo disponible para este curso, pero que su
            lugar está asegurado para el próximo curso de JavaScript.
          </li>
          <li>
            <b>-</b> El calendario de los próximos cursos estará disponible en
            nuestra página web.
          </li>
        </ul>

        <p>Derechos reservados © SRV Education 2024</p>
        <p>
          Email:
          <b>info@wearesrv.com</b> WhatsApp:
          <b>+541166300591</b>
        </p>
        <p>
          Más Información en:
          <b>www.wearesrv.com</b>
        </p>
      </div>
    </div>
  );
}

export default PlanNegocio;
