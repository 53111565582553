import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  CardMedia,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { blogStructure, categories } from '../../../../../iterables/blogs';
import NotFound from '../../../../NotFound';

const StyledCard = styled(Card)(({ theme, disabled, isMobile }) => ({
  maxWidth: 345,
  transition: 'transform 0.3s',
  '&:hover': !isMobile && {
    transform: disabled ? 'none' : 'scale(1.05)',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  color: disabled ? '#b0b0b0' : '#ffffff',
  backgroundColor: disabled ? '#333333' : 'black',
  borderRadius: '60px 2px 60px 2px',
  position: 'relative',
  filter: disabled ? 'grayscale(100%)' : 'none',
  pointerEvents: disabled ? 'none' : 'auto',
  cursor: 'pointer',
}));

const HoverImage = styled(CardMedia)(({ theme }) => ({
  transition: 'opacity 0.3s ease-in-out',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  zIndex: 1,
}));

const ImageContainer = styled('div')({
  position: 'relative',
  width: '100%',

  paddingBottom: '76.25%',
  overflow: 'hidden',
});

const PostContent = styled(CardContent)({
  textAlign: 'center',
});

const FullScreenTitle = styled('h1')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'black',
  color: 'white',
  zIndex: 1000,
  fontWeight: 'bold',
  transition: 'opacity 0.5s',
}));

const ArrowDown = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fontSize: '3rem',
  marginTop: '20px',
  animation: 'bounce 2s infinite',
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-10px)',
    },
    '60%': {
      transform: 'translateY(-5px)',
    },
  },
}));

function BlogIndex() {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showTitle, setShowTitle] = useState(true);
  const [originPath, setOriginPath] = useState('');
  const [title, setTitle] = useState('');
  const [blogs, setBlogs] = useState([blogStructure]);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleFindBlog = () => {
    const path = location.pathname.split('/');
    const searchedCategory = categories.find(
      category => category.id === path[path.length - 1]
    );
    if (searchedCategory) {
      setBlogs(searchedCategory.blogs);
      setTitle(searchedCategory.title);
      setOriginPath(searchedCategory.id);
    } else {
      setBlogs(false);
    }
  };
  const handleNavigate = path => {
    navigate(`/blog/${originPath}/${path}`);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowTitle(window.scrollY < window.innerHeight / 2);
    };
    handleFindBlog();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollBottom = () => {
    const element = document.getElementById('cards');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };
  return blogs ? (
    <>
      <FullScreenTitle
        style={{ opacity: showTitle ? 1 : 0, zIndex: showTitle ? '600' : '0' }}
        onClick={() => handleScrollBottom()}
      >
        {title}
        <ArrowDown />
      </FullScreenTitle>
      <Grid
        id="cards"
        container
        justifyContent="center"
        spacing={4}
        style={{ marginTop: '100vh', textAlign: '-webkit-center' }}
      >
        {blogs.map((blog, index) => (
          <Grid item key={blog.path} xs={12} sm={6} md={4} lg={3}>
            <StyledCard
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
              disabled={blog.disabled}
              onClick={() => handleNavigate(blog.path)}
              isMobile={isMobile}
            >
              <ImageContainer>
                <CardMedia
                  component="img"
                  image={blog.image}
                  alt={blog.title}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: blog.disabled ? 'grayscale(100%)' : 'none',
                  }}
                />
                {!isMobile && (
                  <HoverImage
                    component="img"
                    image={blog.hoverImage}
                    alt={blog.title}
                    style={{ opacity: hoveredCard === index ? 1 : 0 }}
                  />
                )}
              </ImageContainer>
              <PostContent>
                <Typography variant="h5" component="div">
                  {blog.title}
                </Typography>
                <Typography variant="body2" color="inherit">
                  {blog.description}
                </Typography>
              </PostContent>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => handleNavigate(blog.path)}
                  disabled={blog.disabled}
                >
                  Leer Más
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
    </>
  ) : (
    <NotFound />
  );
}

export default BlogIndex;
