import { useState, useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { blogsInfo, blogStructure } from '../../../iterables/blogs';
import LoadingSpinner from '../../Loading';
import NotFound from '../../NotFound';

const blogComponents = {
  'salud-mental': {
    'domina-tus-emociones': lazy(
      () => import('../blog/SaludMental/domina-tus-emociones')
    ),
    'estres-laboral': lazy(() => import('../blog/SaludMental/estres-laboral')),
  },
  abundancia: {
    'el-poder-de-los-pasivos': lazy(
      () => import('../blog/Abundancia/el-poder-de-los-pasivos')
    ),
  },
  'profesion-negocios': {
    'escala-tu-negocio': lazy(
      () => import('../blog/ProfesionYNegocios/escala-tu-negocio')
    ),
    'objetivos-smart': lazy(
      () => import('../blog/ProfesionYNegocios/objetivo-smart')
    ),
    'potencia-tu-impacto': lazy(
      () => import('../blog/ProfesionYNegocios/potencia-tu-impacto')
    ),
  },
  'desarrollo-personal': {
    mentalidad: lazy(() => import('../blog/DesarrolloPersonal/mentalidad')),
  },
};

function BlogIndex() {
  const [blog, setBlog] = useState(blogStructure);
  const location = useLocation();

  const handleFindBlog = () => {
    const path = location.pathname.split('/');
    setBlog(
      blogsInfo.find(blog => blog.id === path[path.length - 1]) || blogStructure
    );
  };

  useEffect(() => {
    handleFindBlog();
  }, [location]);

  const BlogComponent = blogComponents[blog.section.path]?.[blog.id];

  return (
    <>
      {BlogComponent ? (
        <Suspense fallback={<LoadingSpinner />}>
          <BlogComponent blog={blog} />
        </Suspense>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default BlogIndex;
