import React from 'react';
import styles from './mision.module.css';
import SRV from '../../../../images/SRV-TRANSPARENTE.svg';
import { useState, useEffect } from 'react';

function Nosotros() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log('isModalOpen:', isModalOpen);
    if (isModalOpen) {
      document.documentElement.classList.add(styles.noScroll);
    } else {
      document.documentElement.classList.remove(styles.noScroll);
    }
    return () => {
      document.documentElement.classList.remove(styles.noScroll);
    };
  }, [isModalOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <div className={styles.clase1}>
          <h2 className={styles.title}>Mision y Vision</h2>
        </div>
        <div className={styles.clase2}>
          <img src={SRV} alt="SRV" className={styles.img} />
        </div>
        <div className={styles.md} />
        <div className={styles.grid}>
          <div className={styles.grid__left} />
          <div />
          <div className={styles.grid__right} />
        </div>
        <div className={styles.clase3}>
          <div className={styles.videoWrapper}>
            <video
              src="https://cdnsnty.tonyrobbins.com/2024-05-08T17-55-34.717Z-Coaching_Hero_Road.mp4"
              title="Video"
              autoPlay
              muted
              loop
              className={`${styles.video} ${isModalOpen ? styles.hidden : ''}`}
            />
            <button className={styles.viewButton} onClick={handleOpenModal}>
              ▶ Ver Video
            </button>
            <button
              className={styles.viewButtonMobile}
              onClick={handleOpenModal}
            >
              ▶
            </button>

            {/* MODAL SECTION */}
            {isModalOpen && (
              <div className={styles.modal}>
                <div className={styles.modalContent}>
                  <button
                    className={styles.closeButton}
                    onClick={handleCloseModal}
                  >
                    ×
                  </button>
                  <video
                    src="https://cdnsnty.tonyrobbins.com/2024-05-08T17-55-34.717Z-Coaching_Hero_Road.mp4"
                    title="Video"
                    controls
                    autoPlay
                    className={styles.modalVideo}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.clase4}>
          <p className={styles.paragraph}>
            Empoderar a las personas mediante la combinación del aprendizaje
            técnico y el desarrollo personal, proporcionando herramientas
            prácticas y apoyo constante. Buscamos ayudar a cada individuo a
            alcanzar su máximo potencial, equilibrando su vida personal y
            profesional, y logrando un impacto positivo en sus comunidades y en
            el mundo.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;

/* antes de hacer cagada */
