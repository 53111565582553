// src/Sections/ProgramsSection.js
import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Tooltip,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

function Section({ children }) {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <div ref={ref}>
      <Fade bottom when={inView}>
        {children}
      </Fade>
    </div>
  );
}

function CourseCards({ programs, subTitle, title, buttonTitle }) {
  const navigate = useNavigate();
  const handleSeeMore = idName => {
    window.scrollTo({ top: 0 });
    navigate(`/${idName}`);
  };

  return (
    <Container sx={{ py: 8 }}>
      <Section>
        <Typography variant="h4" color="#000" align="center" sx={{ mb: 4 }}>
          {title}
        </Typography>
        <Typography variant="h6" color="#000" align="center" sx={{ mb: 8 }}>
          {subTitle}
        </Typography>
      </Section>
      <Grid container spacing={4}>
        {programs.map((program, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Section>
              <Card class={styles.programCard}>
                <CardMedia
                  class={styles.programImage}
                  component="img"
                  height="400"
                  image={program.image}
                  alt={program.title}
                />
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: '1 0 auto',
                  }}
                >
                  <Tooltip title={program.title}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className={styles.programName}
                      sx={{
                        marginTop: '20px',
                        fontFamily: 'Ubuntu, sans-serif',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {program.title}
                    </Typography>
                  </Tooltip>
                  <Tooltip title={program.description}>
                    <Typography
                      variant="body2"
                      className={styles.programContent}
                      sx={{
                        marginTop: '10px',
                        marginBottom: '20px',
                        fontFamily: 'Ubuntu, sans-serif',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {program.description}
                    </Typography>
                  </Tooltip>
                  <Button
                    class={
                      program.isActive
                        ? styles.programButton
                        : styles.disabledButton
                    }
                    disabled={!program.isActive}
                    onClick={() => handleSeeMore(program.idName)}
                  >
                    {buttonTitle}
                  </Button>
                </CardContent>
              </Card>
            </Section>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CourseCards;
