import React from 'react';

const styles = {
  heading: {
    fontSize: '2em',
    color: '#000',
  },
  image: {
    marginTop: '20px',
    height: '80vh',
  },
};

const PageNotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Página no encontrada</h1>
      <img
        src="../../../not-found.png" // Asegúrate de actualizar esto con la ruta correcta a tu imagen
        alt="Cohete caído"
        style={styles.image}
      />
    </div>
  );
};

export default PageNotFound;
