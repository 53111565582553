import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';
import { motion } from 'framer-motion';

const initialOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
    children: [
      'playToggle',
      'currentTimeDisplay',
      'timeDivider',
      'durationDisplay',
      'remainingTimeDisplay',
      'volumePanel',
      'fullscreenToggle',
    ],
  },
};

const videoJsOptions = {
  techOrder: ['youtube'],
  sources: [
    {
      type: 'video/youtube',
      src: 'https://youtu.be/keXQjRfzAas', // Reemplaza con tu URL de YouTube
    },
  ],
};

export default function Video() {
  const videoNode = useRef(null);
  const player = useRef(null);
  const initialized = useRef(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (videoNode.current && !initialized.current) {
      initialized.current = true; // Evita la inicialización duplicada
      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...videoJsOptions,
      }).ready(function () {
        console.log('Player Ready');

        this.seekingTime_ = 0;

        // Disable seeking
        this.on('seeking', function () {
          this.currentTime(this.seekingTime_);
        });

        this.on('seeked', function () {
          this.currentTime(this.seekingTime_);
        });

        this.on('timeupdate', function () {
          this.seekingTime_ = this.currentTime();
        });

        // Show button when video ends
        this.on('ended', () => {
          setShowButton(true);
        });
      });
    }
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);

  return (
    <div
      className="video-container"
      style={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}
    >
      <video
        ref={videoNode}
        className="video-js"
        aria-label="Video de YouTube"
      />
      {showButton && (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <motion.button
            onClick={() =>
              (window.location.href =
                'https://pay.hotmart.com/V91996078A?checkoutMode=6&off=4tqhxxdv&offDiscount=REVOLUCIONSRV')
            } // Reemplaza con la URL de destino
            style={{
              fontSize: 'calc(1rem + 0.5vw)',
              padding: '10px 20px',
              backgroundColor: '#4caf50',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              cursor: 'pointer',
            }}
            animate={{
              opacity: [1, 0.7, 1],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
            whileHover={{ filter: 'brightness(1.5)' }}
            aria-label="Reclama tu descuento"
          >
            RECLAMA TU DESCUENTO
          </motion.button>
        </div>
      )}
    </div>
  );
}
