import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import LandingInfoProducto from './LandingInfoProducto';
import Conocenos from './Conocenos';
import PropuestaSAAC from './Landings/SAAC/Propuesta';
import SAACPlanNegocio from './Landings/SAAC/PlanNegocio';
import SAACJavascript from './Landings/SAAC/JavaScriptInscripcion';
import SalvadorPlanNegocio from './Landings/Salvador/PlanNegocio';
import SalvadorJavascript from './Landings/Salvador/JavaScriptInscripcion';
import CalendarioJavaScript from './CalendarioJavaScript';
import TallerDAR from './Landings/TallerDAR/TallerDAR';
import theme from './theme';
import NotFound from './components/NotFound';
import './App.css';

import JavaScriptDesdeCero from './Landings/JavaScriptDesdeCero';
import BackEndJavaScript from './Landings/BackEndJavaScript';
import Scripting from './Landings/Scripting';
import { trackPageView } from './facebookPixel';
import HeroSection from './Sections/HeaderSection';
import TestimonialsSection from './Sections/TestimonialsSection';

import Historia from './components/NavBarButtons/nosotros/historia';
import MisionVision from './components/NavBarButtons/nosotros/mision-vision';

import Streams from './components/NavBarButtons/comunidad/streams';
import Categories from './components/Categories/Categories';
import ContactForm from './components/ContactForm';
import CourseCards from './components/CourseCards';
import Category from './components/NavBarButtons/blog/blogsComponents/Category';
import Blog from './components/NavBarButtons/blog';

import Profile from './components/Profiles';

import {
  blogCards,
  tiendaCards,
  nosotrosCards,
  comunidadCards,
  profileCards,
} from './iterables/categoryCards';

import { courses, talleres } from './iterables/courses';

function Main() {
  const location = useLocation();
  useEffect(() => {
    trackPageView();
  }, [location]);

  const hideHeaderFooter =
    location.pathname === '/landing/abundancia' ||
    location.pathname === '/landing/conocenos' ||
    location.pathname === '/saac' ||
    location.pathname === '/saac/javascript' ||
    location.pathname === '/salvador/javascript' ||
    location.pathname === '/javascript-desde-cero';

  return (
    <div className="App">
      <Helmet>
        <title>SRV - Desarrollo Personal y Profesional</title>
        <meta
          name="description"
          content="Empoderamos a las personas mediante la combinación del aprendizaje técnico y el desarrollo personal."
        />
        <meta
          name="keywords"
          content="desarrollo personal, aprendizaje técnico, programación, crecimiento emocional, SRV"
        />
      </Helmet>
      {!hideHeaderFooter && <Header />}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <CourseCards
                programs={courses}
                buttonTitle={'Saber Más'}
                title={'Nuestros Programas'}
                subTitle={'Selecciona el que mas se ajuste a ti'}
              />
              <TestimonialsSection />
            </>
          }
        />

        <Route
          path="/blog"
          element={
            <Categories cards={blogCards} title="Blog" originPath="blog" />
          }
        />

        <Route path="/blog/:category" element={<Category />} />
        <Route path="/blog/:category/:blogId" element={<Blog />} />

        <Route
          path="/tienda"
          element={
            <Categories
              cards={tiendaCards}
              title="Tienda"
              originPath="tienda"
            />
          }
        />
        <Route
          path="/comunidad"
          element={
            <Categories
              cards={comunidadCards}
              title="Comunidad"
              originPath="comunidad"
            />
          }
        />
        <Route
          path="nosotros/profile"
          element={
            <Categories
              cards={profileCards}
              title="Founders"
              originPath="profile"
            />
          }
        />

        <Route
          path="/profile/agustin-fernandez"
          element={<Profile profilePath="agustin-fernandez" />}
        />

        <Route
          path="/profile/marcos-schmite"
          element={<Profile profilePath="marcos-schmite" />}
        />

        <Route
          path="/profile/milagros-espinosa"
          element={<Profile profilePath="milagros-espinosa" />}
        />

        <Route
          path="/profile/fabio-gomez"
          element={<Profile profilePath="fabio-gomez" />}
        />

        <Route
          path="/nosotros"
          element={
            <Categories
              cards={nosotrosCards}
              title="Nosotros"
              originPath="nosotros"
            />
          }
        />

        <Route
          path="/nosotros/historias-de-exito"
          element={<TestimonialsSection />}
        />
        <Route
          path="/nosotros/participa"
          element={
            <ContactForm
              title="¿Queres formar parte de SRV?"
              descriptionLabel="Contactanos"
              responseMessage="Gracias por confiar en nosotros, pronto nos pondremos en contacto con vos"
              description="¿Estás buscando una oportunidad laboral única? ¿Tienes ideas innovadoras y deseas colaborar y aprender de personas altamente competentes en el mercado laboral? ¡No dudes más y aprovecha esta oportunidad única! Somos SRV, somos Revolución."
            />
          }
        />
        <Route path="/nosotros/sobre-srv" element={<Historia />} />
        <Route path="/nosotros/mision-vision" element={<MisionVision />} />

        <Route
          path="/tienda/todos-los-productos"
          element={
            <CourseCards
              programs={courses}
              buttonTitle={'Saber Más'}
              title={'Nuestros Cursos'}
              subTitle={'Selecciona el que mas se ajuste a ti'}
            />
          }
        />
        <Route
          path="/tienda/negocios"
          element={
            <ContactForm
              title="¿Necesitas ayuda con tu Negocio? Contactanos"
              descriptionLabel="Cuentanos como podemos ayudarte"
              responseMessage="Gracias por confiar en nosotros, pronto nos pondremos en contacto con vos"
              description="Saber cómo escalar un negocio requiere entender la diferencia entre crecer y escalar. Cuando haces crecer un negocio, añades clientes y miembros del equipo mientras los costos también aumentan. En otras palabras, tu negocio está creciendo en tamaño pero no en ingresos, lo cual no es sostenible, especialmente si sufres una disminución en la clientela. Si esto sucede, te quedarás con empleados y recursos pero sin trabajo ni ingresos para mantenerlos."
            />
          }
        />
        <Route
          path="/tienda/vida"
          element={
            <ContactForm
              title="Puedes conseguir la vida que tanto deseas, contactanos"
              descriptionLabel="Cuentanos que quieres mejorar aqui"
              responseMessage="Gracias por confiar en nosotros, pronto nos pondremos en contacto con vos"
              description="¿Qué harías si supieras que podrías lograr el éxito solo aprendiendo a cambiar tu mentalidad? Eso es lo que Josh Iris aprendió después de trabajar con un Coach de Resultados. Como presidente de una empresa de motores eléctricos, a Josh le iba bastante bien equilibrando sus responsabilidades laborales con su vida personal. Pero sabía que había más para él."
            />
          }
        />
        <Route
          path="/tienda/talleres"
          element={
            <CourseCards
              programs={talleres}
              buttonTitle={'Saber Más'}
              title={'Nuestros Talleres'}
              subTitle={'Selecciona el que mas se ajuste a ti'}
            />
          }
        />

        <Route
          path="/comunidad/quiero-stremear"
          element={
            <ContactForm
              title="Stremear nunca fue tan facil, contactanos"
              descriptionLabel="Cuentanos tu idea"
              responseMessage="Gracias por confiar en nosotros, pronto nos pondremos en contacto con vos"
              description="Todo gran cambio empieza con una idea. La fomentación de comunidad es uno de nuestros pilares más importantes, ya que creemos firmemente en el poder de las conexiones y el apoyo mutuo. Por eso, ponemos al servicio de los demás nuestra vasta experiencia y todos nuestros recursos, para que tú también puedas brillar. Nos dedicamos a crear un entorno en el que cada individuo se sienta valorado y apoyado, donde las ideas pueden florecer y convertirse en realidades de alto impacto."
            />
          }
        />

        <Route path="/comunidad/streams" element={<Streams />} />

        <Route path="/SAAC" element={<PropuestaSAAC />} />
        <Route path="/SAAC/javascript" element={<SAACPlanNegocio />} />
        <Route
          path="/SAAC/javascript/inscripcion"
          element={<SAACJavascript />}
        />
        <Route path="/salvador/javascript" element={<SalvadorPlanNegocio />} />
        <Route
          path="/salvador/javascript/inscripcion"
          element={<SalvadorJavascript />}
        />
        <Route
          path="/javascript/calendario"
          element={<CalendarioJavaScript />}
        />
        <Route
          path="/javascript-desde-cero"
          element={<JavaScriptDesdeCero />}
        />
        <Route path="/scripting" element={<Scripting />} />
        <Route path="/backend-javascript" element={<BackEndJavaScript />} />
        <Route path="/taller-dar" element={<TallerDAR />} />
        <Route path="/landing/abundancia" element={<LandingInfoProducto />} />
        <Route path="/landing/conocenos" element={<Conocenos />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
