import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Helmet } from 'react-helmet';
import Countdown from 'react-countdown';
import { motion } from 'framer-motion';
import useMediaQuery from '@mui/material/useMediaQuery';

const roles = [
  'Administración y Finanzas',
  'Recursos Humanos',
  'Operaciones',
  'Ventas y Marketing',
  'Tecnología de la Información (TI)',
  'Servicio al Cliente',
  'Compras y Abastecimiento',
  'Legal y Cumplimiento',
  'Desarrollo Corporativo y Estrategia',
  'Investigación y Desarrollo (I+D)',
  'Programador',
  'Otro',
];

function PlanNegocio() {
  const [email, setEmail] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [dni, setDni] = useState('');
  const [celular, setCelular] = useState('');
  const [rol, setRol] = useState('');
  const [otroRol, setOtroRol] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !validateEmail(email) ||
      !nombre ||
      !apellido ||
      !dni ||
      !celular ||
      (!rol && !otroRol)
    ) {
      setFormError(true);
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSfzIKhAWhF9pSX8DZSMbUlmnh2exqHQhiuaGvSFiLXH84lBnQ/formResponse';
    const emailEntryId = 'entry.1750272805';
    const apellidoEntryId = 'entry.130616501';
    const nombreEntryId = 'entry.1069060505';
    const dniEntryId = 'entry.1281031995';
    const celularEntryId = 'entry.2067204794';
    const rolEntryId = 'entry.811726002';

    const formData = new FormData();
    formData.append(emailEntryId, email);
    formData.append(nombreEntryId, nombre);
    formData.append(apellidoEntryId, apellido);
    formData.append(dniEntryId, dni);
    formData.append(celularEntryId, celular);
    formData.append(rolEntryId, rol === 'Otro' ? otroRol : rol);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setEmail('');
      setNombre('');
      setApellido('');
      setDni('');
      setCelular('');
      setRol('');
      setOtroRol('');
      setEmailError(false);
      setFormError(false);
    } catch (error) {
      console.error(error);
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      {days}d {hours}h {minutes}m {seconds}s
    </span>
  );

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <img
        src={isMobile ? '../tallerDarResponsive.png' : '../tallerDar.png'}
        alt="Taller D.A.R."
      />
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          padding: '5%',
          minHeight: '100vh',
        }}
      >
        <Helmet>
          <title>
            Taller D.A.R.: Desarrollo de Alto Rendimiento - SRV 2024
          </title>
          <meta
            name="description"
            content="Únete a nuestro Taller D.A.R. y descubre cómo alcanzar tu máximo potencial en el desarrollo personal y profesional. Cupos limitados y descuentos exclusivos esta semana."
          />
          <meta
            name="keywords"
            content="taller desarrollo, alto rendimiento, SRV, programación, JavaScript, crecimiento personal"
          />
        </Helmet>
        <motion.div
          initial={{ textShadow: '0 0 10px #fff' }}
          animate={{
            textShadow: [
              '0 0 10px #000000',
              '0 0 20px #FF0000',
              '0 0 30px #fff',
              '0 0 20px #FF0000',
              '0 0 10px #000000',
            ],
          }}
          transition={{ duration: 2, repeat: Infinity }}
        >
          <Typography
            variant="h2"
            gutterBottom
            style={{
              color: '#FF0000',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            EL DESCUENTO TERMINA EN: <> </>
            <Countdown
              date={new Date('2024-06-30T00:00:00')}
              renderer={renderer}
            />
          </Typography>
        </motion.div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box sx={{ width: { xs: '100%', md: '48%' }, textAlign: 'left' }}>
            <Typography
              variant="body1"
              style={{
                color: '#FFFFFF',
                marginBottom: '16px',
                textAlign: 'justify',
              }}
            >
              <span
                style={{
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  color: '#32CD32',
                }}
              >
                70% de Descuento Solo por Esta Semana
              </span>
              <motion.div
                initial={{ textShadow: '0 0 10px #fff' }}
                animate={{
                  textShadow: [
                    '0 0 10px #000000',
                    '0 0 20px #FF0000',
                    '0 0 30px #fff',
                    '0 0 20px #FF0000',
                    '0 0 10px #000000',
                  ],
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    fontSize: '1.5em',
                    color: '#FF0000',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  ¡Cupos Limitados!
                </Typography>
              </motion.div>
              ¡Aprovecha esta oferta única! Solo por esta semana, obtén un{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#32CD32',
                  fontSize: '1.5em',
                }}
              >
                70% de descuento
              </span>{' '}
              en nuestro exclusivo
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#00FFD1',
                  fontSize: '1.5em',
                }}
              >
                Taller D.A.R.
              </span>
              : Desarrollo de Alto Rendimiento. Además, al comprar este taller,
              te llevas completamente
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#32CD32',
                  fontSize: '1.5em',
                }}
              >
                {' '}
                GRATIS{' '}
              </span>{' '}
              nuestro curso de
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#00FFD1',
                  fontSize: '1.5em',
                }}
              >
                {' '}
                JavaScript desde CERO
              </span>
              .
              <br />
              <span
                style={{
                  fontSize: '1.3em',
                  fontWeight: 'bold',
                  color: '#00FFD1',
                }}
              >
                Taller D.A.R.: Tu Camino al Éxito
              </span>
              <br />
              <span style={{ fontWeight: 'bold' }}>Duración del Taller:</span> 4
              horas intensivas.
              <br />
              <span style={{ fontWeight: 'bold' }}>Fecha:</span> Domingo 7 de
              julio, de 10:00 AM a 14:00 PM.
              <br />
              <span style={{ fontWeight: 'bold' }}>Lugar:</span>
              <a
                href="https://maps.app.goo.gl/aDTgjEQh6EQ9xkk17"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Cachi 724, Comuna 4, CABA.
              </a>
              <br />
              <span style={{ fontWeight: 'bold' }}>Incluye Refrigerios</span>
              .
              <br />
              <br />
              <span
                style={{
                  fontSize: '1.3em',
                  fontWeight: 'bold',
                  color: '#00FFD1',
                }}
              >
                Beneficios del Taller:
              </span>
              <br />
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mi cuerpo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Yoga y Tai Chi para fortalecer la conexión física y reducir
                    el estrés.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mi mente?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Meditación y concentración para mejorar el enfoque y la
                    claridad mental.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mis emociones?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Técnicas de imaginación activa y aceleración emocional para
                    manejar y potenciar las emociones.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mi legado?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Desarrollo de un propósito superior basado en el servicio
                    desinteresado.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mi intelecto?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Adquiere habilidades técnicas en programación, incluyendo
                    JavaScript y Google Apps Script.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{ backgroundColor: 'transparent', color: '#FFFFFF' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
                    ¿Qué beneficios traerá a mi desarrollo técnico?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ color: '#FFFFFF' }}>
                    Optimiza tu tiempo con mejores prácticas en tecnología,
                    aprende sobre IA, y despliega aplicaciones usando DevOps y
                    AWS.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <br />
              <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                Lo que lograrás:
              </span>
              <br />
              - Explotar al máximo tus habilidades técnicas, actitudes y
              emociones.
              <br />
              - Crear herramientas efectivas y de gran impacto.
              <br />
              - Alcanzar un balance integral en tus roles personales y
              profesionales.
              <br />
              - Convertirte en la mejor versión de ti mismo.
              <br />
              <br />
              ¡No dejes pasar esta oportunidad única de transformar tu vida
              profesional y personal! Los cupos son limitados y se están
              agotando rápidamente.
              <br />
              <br />
              <span
                style={{
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  color: '#FF7318',
                }}
              >
                Inscríbete ahora
              </span>{' '}
              y obtén nuestro curso de
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#00FFD1',
                  fontSize: '1.5em',
                }}
              >
                JavaScript desde CERO
              </span>{' '}
              totalmente gratis. Haz clic en el botón de abajo para asegurar tu
              lugar en el
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#00FFD1',
                  fontSize: '1.5em',
                }}
              >
                Taller D.A.R.
              </span>
              .
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '40%' }, textAlign: 'left' }}>
            <Card
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '16px',
              }}
            >
              <CardContent>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nombre"
                    label="Nombre"
                    name="nombre"
                    autoComplete="nombre"
                    value={nombre}
                    onChange={e => setNombre(e.target.value)}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="apellido"
                    label="Apellido"
                    name="apellido"
                    autoComplete="apellido"
                    value={apellido}
                    onChange={e => setApellido(e.target.value)}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="dni"
                    label="DNI"
                    name="dni"
                    autoComplete="dni"
                    value={dni}
                    onChange={e => setDni(e.target.value)}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="celular"
                    label="Celular"
                    name="celular"
                    autoComplete="celular"
                    value={celular}
                    onChange={e => setCelular(e.target.value)}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Dirección de correo electrónico"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                      setEmailError(!validateEmail(e.target.value));
                    }}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                    error={emailError}
                    helperText={
                      emailError
                        ? 'Por favor, ingrese un correo electrónico válido.'
                        : ''
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    select
                    id="rol"
                    label="Ocupacion"
                    name="rol"
                    value={rol}
                    onChange={e => setRol(e.target.value)}
                    InputLabelProps={{ style: { color: '#FFFFFF' } }}
                    InputProps={{ style: { color: '#FFFFFF' } }}
                  >
                    {roles.map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </TextField>
                  {rol === 'Otro' && (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="otroRol"
                      label="Especifica tu rol"
                      name="otroRol"
                      value={otroRol}
                      onChange={e => setOtroRol(e.target.value)}
                      InputLabelProps={{ style: { color: '#FFFFFF' } }}
                      InputProps={{ style: { color: '#FFFFFF' } }}
                    />
                  )}
                  {formError && (
                    <Typography variant="body2" color="error" align="center">
                      Todos los campos son obligatorios y el correo electrónico
                      debe ser válido.
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: '#FF7318',
                      color: '#000000',
                    }}
                  >
                    Enviar
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default PlanNegocio;
