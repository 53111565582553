import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

function CustomForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError(true);
      setMessage('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    const formUrl =
      'https://docs.google.com/forms/d/e/1FAIpQLSf0Km7lwLaZNzVPxX9uWyEzGdYGJ88hoYu75-zFWqW3JtB0BQ/formResponse';
    const emailEntryId = 'entry.356532992'; // Reemplaza esto con el ID real de tu campo de correo electrónico

    const formData = new FormData();
    formData.append(emailEntryId, email);

    try {
      await fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });
      setMessage('¡Gracias por suscribirte!');
      setEmail('');
      setEmailError(false);
    } catch (error) {
      setMessage('Hubo un error al suscribirte. Por favor, intenta de nuevo.');
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '16px',
          padding: 2,
          maxWidth: 400,
        }}
      >
        <CardContent>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Typography variant="h6" gutterBottom style={{ color: '#000000' }}>
              Suscríbete para recibir información
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Dirección de correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setEmailError(!validateEmail(e.target.value));
              }}
              InputLabelProps={{ style: { color: '#000000' } }}
              InputProps={{ style: { color: '#000000' } }}
              aria-label="Correo electrónico"
              error={emailError}
              helperText={
                emailError
                  ? 'Por favor, ingresa un correo electrónico válido.'
                  : ''
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              aria-label="Enviar formulario"
            >
              Enviar
            </Button>
            {message && (
              <Typography
                style={{ color: '#000000' }}
                variant="body2"
                role="alert"
              >
                {message}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CustomForm;
