// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // Aquí puedes personalizar tu tema
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#fafafa',
    },
    // Puedes definir otros colores y ajustes aquí
  },
  // Otros ajustes de tema pueden ir aquí
});

export default theme;
